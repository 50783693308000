

export const Translation = {
  pleaseConfirm: {
    ar: 'الرجاء التأكيد',
    en: 'Please Confirm'
  },
  areYouSureYouWantToDelete: {
    ar: 'هل أنت متأكد بإنك تريد الحذف؟',
    en: 'Are you sure, you want to delete?'
  },
  areYouSure: {
    ar: 'هل أنت متأكد؟',
    en: 'Are you sure?'
  },
  areYouSureYouWantToRemoveAssign: {
    ar: 'هل أنت متأكد بإنك تريد إزالة التخصيص ؟',
    en: 'Are you sure, you want to remove the assign ?'
  },
  deletedSuccessfully: {
    ar: 'تم الحذف بنجاح',
    en: 'Deleted Successfully'
  },
  addedSuccessfully: {
    ar: 'تم الإضافة بنجاح',
    en: 'Added Successfully'
  },
  savedSuccessfully: {
    ar: 'تم الحفظ بنجاح',
    en: 'Saved Successfully'
  },
  removeAssignedSuccessfully: {
    ar: 'تم إزالة التخصيص بنجاح',
    en: 'Assigned Removed Successfully'
  },
  assignedSuccessfully: {
    ar: 'تم التخصيص بنجاح',
    en: 'Assigned Successfully'
  },
  selectAim: {
    ar: 'إختر الغاية',
    en: 'Select Aim'
  },
  selectStrategyGoal: {
    ar: 'إختر هدف الإستراتجية',
    en: 'Select Strategy Goal'
  },
  selectBalancedScorecard: {
    ar: 'إختر بطاقة الأداء المتوازن',
    en: 'Select Balanced Scorecard'
  },
  selectSector: {
    ar: 'إختر القطاع  ',
    en: 'Select Sector'
  },
  weight: {
    ar: 'الوزن',
    en: 'Weight'
  },
  name: {
    ar: 'الإسم',
    en: 'Name'
  },
  nameEnglish: {
    ar: 'الإسم باللغة الإنجليزية',
    en: 'Name in English'
  },
  nameArabic: {
    ar: 'الإسم باللغة العربية',
    en: 'Name in Arabic'
  },
  description: {
    ar: 'الوصف',
    en: 'Description'
  },
  selectDepartment: {
    ar: 'إختر الإدارة',
    en: 'Select Department'
  },
  selectSectorObjective: {
    ar: 'إختر هدف القطاع',
    en: 'Select Sector Objective'
  },
  select: {
    ar: 'إختر',
    en: 'Select'
  },
  aggregationMethod: {
    ar: 'طريقة الإحتساب',
    en: 'Aggregation Method'
  },
  calculationPeriod: {
    ar: 'دورية القياس',
    en: 'Calculation Period'
  },
  valueType: {
    ar: 'وحدة القياس',
    en: 'Value Type'
  },
  status: {
    ar: 'الحالة',
    en: 'Status'
  },
  classification: {
    ar: 'التصنيف',
    en: 'Classification'
  },
  subClassification: {
    ar: 'التصنيف الفرعي',
    en: 'Sub-Classification'
  },
  trend: {
    ar: 'مؤشر الأداء',
    en: 'Trend'
  },
  yes: {
    ar: 'نعم',
    en: 'Yes'
  },
  no: {
    ar: 'كلا',
    en: 'No'
  },
  sector: {
    ar: 'القطاع',
    en: 'Sector'
  },
  department: {
    ar: 'الإدارة',
    en: 'Department'
  },
  section: {
    ar: 'القسم',
    en: 'Section'
  },
  updateKpi: {
    ar: 'تحديث المؤشر',
    en: 'Update KPI'
  },
  departmentGoal: {
    ar: 'هدف الإدارة',
    en: 'Department Goal'
  },
  dataSubmitted: {
    ar: 'تم إضافة البيانات',
    en: 'Data is Submitted'
  },
  theme: {
    ar: 'المحور',
    en: 'Theme'
  },
  manageThemeDetails: {
    ar: 'إدارة تفاصيل المحور',
    en: 'Manage Theme Details'
  },
  manageMainStandardDetails: {
    ar: 'إدارة تفاصيل المعيار الرئيسي',
    en: 'Manage Main Standard Details'
  },
  mainStandard: {
    ar: 'المعيار الرئيسي',
    en: 'Main Standard'
  },
  manageSubStandardDetails: {
    ar: 'إدارة تفاصيل المعيار الفرعي',
    en: 'Manage Sub Standard Details'
  },
  subStandard: {
    ar: 'المعيار الفرعي',
    en: 'Sub Standard'
  },
  manageStrategyObjectiveKpis: {
    ar: 'إدارة مؤشرات الأهداف الإستراتيجية',
    en: 'Manage Strategy Objective KPIs'
  },
  strategyObjective: {
    ar: 'هدف الإستراتيجية',
    en: 'Strategy Objective'
  },
  numberOfYears: {
    ar: 'عدد السنين',
    en: 'Number of Years'
  },
  objective: {
    ar: 'الهدف',
    en: 'Objective'
  },
  objectiveStrategy: {
    ar: 'الرؤية',
    en: 'Objective'
  },
  vision: {
    ar: 'الرسالة',
    en: 'Vision'
  },
  mission: {
    ar: 'المهمة',
    en: 'Mission'
  },
  aim: {
    ar: 'الهدف',
    en: 'Aim'
  },
  swotSummary: {
    ar: 'ملخص التحليل الرباعي',
    en: 'SWOT Summary'
  },
  strengths: {
    ar: 'نقاط القوة',
    en: 'Strengths'
  },
  weaknesses: {
    ar: 'نقاط الضعف',
    en: 'Weaknesses'
  },
  opportunities: {
    ar: 'الفرص',
    en: 'Opportunities'
  },
  threats: {
    ar: 'التهديات',
    en: 'Threats'
  },
  actualValue: {
    ar: 'القيمة الحقيقية',
    en: 'Actual Value'
  },
  targetValue: {
    ar: 'القيمة المستهدفة',
    en: 'Target Value'
  },
  username: {
    ar: 'إسم المستخدم',
    en: 'Username'
  },
  password: {
    ar: 'الرقم السري',
    en: 'Password'
  },
  wrongUsernameOrPassword: {
    ar: 'إسم المستخدم أو الرقم السري غير صحيح',
    en: 'Wrong Username or Password!'
  },
  eynPass: {
    ar: 'fgdfgndfgjufersdforufsfsjsoijsjsfospfurfsfspuwsefzopjs',
    en: 'fgdfgndfgjufersdforufsfsjsoijsjsfospfurfsfspuwsefzopjs'
  },
  approvedSucessfully: {
    ar: 'تم الإعتماد بنجاح',
    en: 'Approved Sucessfully'
  },
  rejectedSucessfully: {
    ar: 'تم الرفض بنجاح',
    en: 'Rejected Sucessfully'
  },
  removeKpiRequestSentSuccessfully: {
    ar: 'تم إرسال طلب إزالة تخصيص المؤشر بنجاح',
    en: 'Remove Kpi Request Sent Successfully'
  },
  sentSuccessfully: {
    ar: 'تم إرسال الطلب بنجاح',
    en: 'Sent Successfully'
  },
  returnedToKpiList: {
    ar: 'تم إعادة المؤشر إلى قائمة المؤشرات',
    en: 'Kpi returned to Kpi list'
  },
  sentToWaitingList: {
    ar: 'تم الإرسال إلى قائمة الإنتظار',
    en: 'Sent to waiting list'
  },
  removedFromWaitingList: {
    ar: 'تم الإزالة من  قائمة الإنتظار',
    en: 'Removed from waiting list'
  },
  anErrorOccurred: {
    ar: 'حدث خطأ',
    en: 'An Error Occurred'
  },
  uploadSuccessfully: {
    ar: 'تم رفع الملف بنجاح',
    en: 'File Uploaded Successfully'
  },
  mysteryShopper: {
    ar: 'المتسوق السّرّي',
    en: 'Mystery Shopper'
  },
  manageMysteryShopper: {
    ar: 'إدارة المتسوق السّرّي',
    en: 'Manage Mystery Shopper'
  },
  visit: {
    ar: 'الزيارة',
    en: 'Visit'
  },
  visitDetails: {
    ar: 'تفاصيل الزيارة',
    en: 'Visit Details'
  },
  improvementOpportunity: {
    ar: 'فرص التحسين',
    en: 'Improvement Opportunity'
  },
  correctionAndDevelopmentPlan: {
    ar: 'خطة التصحيح و التطوير',
    en: 'Correction and Development Plan'
  },
  causation: {
    ar: 'السببية',
    en: 'Causation'
  },
  serviceChannel: {
    ar: 'قناة تقديم الخدمة',
    en: 'Service Channel'
  },
  visitDate: {
    ar: 'تاريخ الزيارة',
    en: 'Visit Date'
  },
  reportNumber: {
    ar: 'رقم التقرير',
    en: 'Report Number'
  },
  result: {
    ar: 'النتيجة',
    en: 'result'
  },
  improvementOpportunityType: {
    ar: 'نوع فرصة التحسين',
    en: 'Improvement Opportunity Type'
  },
  actions: {
    ar: 'الإجراءات',
    en: 'Actions'
  },
  startDate: {
    ar: 'تاريخ البداية',
    en: 'Start Date'
  },
  finishDate: {
    ar: 'تاريخ الإنتهاء',
    en: 'Finish Date'
  },
  fromStartDate: {
    ar: 'من تاريخ البداية',
    en: 'From Start Date'
  },
  fromFinishDate: {
    ar: 'من تاريخ الإنتهاء',
    en: 'From Finish Date'
  },
  tillStartDate: {
    ar: 'الى تاريخ البداية',
    en: 'Till Start Date'
  },
  tillFinishDate: {
    ar: 'الى تاريخ الإنتهاء',
    en: 'Till Finish Date'
  },
  mainManager: {
    ar: 'المسؤول الرئيسي',
    en: 'Main Manager'
  },
  executiveManager: {
    ar: 'المسؤول التنفيذي',
    en: 'Executive Manager'
  },
  isExecutiveManagerSystemUser: {
    ar: 'المسؤول التنفيذي من الموظفين',
    en: 'Executive manager is from employees'
  },
  kpiCorrectionAndDevelopmentPlan: {
    ar: 'مؤشرات خطط التصحيح و التطوير',
    en: 'Correction And Development Plan KPIs'
  },
  correctionAndDevelopmentPlanStatus: {
    ar: 'حالة خطط التصحيح و التطوير',
    en: 'Correction and development plan status'
  },
  correctionAndDevelopmentPlanCausationType: {
    ar: 'نوع سببية خطط التصحيح و التطوير',
    en: 'Correction and development plan causation type'
  },
  mysteryShopperConfigurationManagement: {
    ar: 'إدارة إعدادات المتسوق السّرّي',
    en: 'Mystery shopper configuration management'
  },
  visits: {
    ar: 'الزيارات',
    en: 'Visits'
  },
  visitStatus: {
    ar: 'حالة الزيارة',
    en: 'Visit Status'
  },
  actualDate: {
    ar: 'التاريخ الفعلي',
    en: 'Actual Date'
  },
  expectedDate: {
    ar: 'التاريخ المتوقع',
    en: 'Expected Date'
  },
  haveBudget: {
    ar: 'يوجد ميزانية',
    en: 'Have Budget'
  },
  noOpportunities: {
    ar: 'لا يوجد فرص',
    en: 'No Opportunities'
  },
  noCorrectiveAndImprovmentPlans: {
    ar: 'لا يوجد خطط تصحيح و تطوير',
    en: 'No Corrective and Improvment Plans'
  },
  operationalMap: {
    ar: 'الخريطة التشغيلية',
    en: 'Operational Map'
  },
  sharedProject: {
    ar: 'المشاريع المشتركة',
    en: 'Shared Project'
  },
  theTotalSumOfTheWeightsShouldBeEqualToOneHundred: {
    ar: 'مجموع الأوزان يجب أن يساوي 100',
    en: 'The total sum of the weights should be equal to 100'
  },
  innovationConfiguration: {
    ar: 'إدارة الإبتكار',
    en: 'Innovation Configuration'
  },
  innovationType: {
    ar: 'نوع الإبتكار',
    en: 'Innovation Type'
  },
  suggestionEntity: {
    ar: 'جهة الإقتراح',
    en: 'Suggestion Entity'
  },
  suggestionStatus: {
    ar: 'حالة الإقتراح',
    en: 'Suggestion Status'
  },
  code: {
    ar: 'الرمز',
    en: 'Code'
  },
  innovation: {
    ar: 'الإبتكار',
    en: 'Innovation'
  },
  suggestions: {
    ar: 'الإقتراحات',
    en: 'Suggestions'
  },
  quarter: {
    ar: 'الربع',
    en: 'Quarter'
  },
  year: {
    ar: 'السنة',
    en: 'Year'
  },
  thirdParty: {
    ar: 'جهة خارجية',
    en: 'Third Party'
  },
  internal: {
    ar: 'داخلي',
    en: 'Internal'
  },
  innovationCriteria: {
    ar: 'معايير الإبتكار',
    en: 'Innovation Criteria'
  },
  mainCriteria: {
    ar: 'المعيار الرئيسي',
    en: 'Main Criteria'
  },
  ratingTemplate: {
    ar: 'نموذج تقييم',
    en: 'Rating Template'
  },
  isActive: {
    ar: 'فعّال',
    en: 'Is Active'
  },
  isTaskOrSubMsg: {
    ar: 'هل يوجد أي مهمة فرعية أسفل هذه المهمة؟',
    en: 'Is there any sub mission under this mission?'
  },
  rating: {
    ar: 'التقييم',
    en: 'Rating'
  },
  modifiedBy: {
    ar: 'تم التعديل بواسطة',
    en: 'Modified By'
  },
  modifiedDate: {
    ar: 'تاريخ التعديل',
    en: 'Modified Date'
  },
  approvedBy: {
    ar: 'تم الإعتماد بواسطة',
    en: 'Approved By'
  },
  labelName: {
    ar: 'إسم الحقل',
    en: 'Label Name'
  },
  oldValue: {
    ar: 'البيانات السابقة',
    en: 'Old Value'
  },
  newValue: {
    ar: 'البينات الحالية',
    en: 'New Value'
  },
  continueToLearningAndChallengingPoints: {
    ar: 'سيتم تحويلك الى صفحة إدخال نقاط التعلم و التحديات, الرجاء المتابعة لإكمال إنجاز المشروع',
    en: 'You will be redirected to challenging and learning points, please press continue to finish the project.'
  },
  continue: {
    ar: 'المتابعة',
    en: 'Continue'
  },
  continueLater: {
    ar: 'المتابعة لاحقاً',
    en: 'Continue Later'
  },
  addLearningAndChallengingPoints: {
    ar: 'أدخل نقاط التعلم و التحديات ثم ارسل لإتمام إنجاز المشروع',
    en: 'Add challenging and learning points then click send to finish the project.'
  },
  isInnovativeProject: {
    ar: 'هل المشروع مبتكر؟',
    en: 'Is this project innovative?'
  },
  innovativeProjects: {
    ar: 'المشاريع المبتكرة',
    en: 'Innovative Projects'
  },
  search: {
    ar: 'البحث',
    en: 'Search'
  },
  sectorGoal: {
    ar: 'هدف القطاع',
    en: 'Sector Goal'
  },
  innovative: {
    ar: 'مبتكر',
    en: 'innovative'
  },
  notInnovative: {
    ar: 'غير مبتكر',
    en: 'Not Innovative'
  },
  employee: {
    ar: 'الموظف',
    en: 'Employee'
  },
  transformedIntoProject: {
    ar: 'تحول الى مشروع',
    en: 'transformedIntoProject'
  },
  resultGreaterThan: {
    ar: 'النتيجة أكبر من',
    en: 'Result greater than'
  },
  resultLessThan: {
    ar: 'النتيجة أصغر من',
    en: 'Result less than'
  },
  labelNameMissing: {
    ar: 'إسم الحقل غير موجود',
    en: 'Label name is missing'
  },
  strategyPlan: {
    ar: 'الخطة الإستراتيجية',
    en: 'Strategy Plan'
  },
  kpiAssignInfo: {
    ar: 'تفاصيل عن المؤشرات المخصصة',
    en: 'KPIs Assign Info'
  },
  relatedEntity: {
    ar: 'الجهة المعنية',
    en: 'Related Entity'
  },
  calculationMethod: {
    ar: "طريقة الإحتساب",
    en: "Calculation Method"
  },
  whatDoYouWantToEdit: {
    ar: 'ما الذي تريد تعديله؟',
    en: 'What do you want to edit?'
  },
  kpiDetails: {
    ar: 'تفاصيل المؤشر',
    en: 'KPI Details'
  },
  kpiValues: {
    ar: 'قيم المؤشر',
    en: 'KPI Values'
  },
  weightPercentage: {
    ar: 'الوزن نسبة',
    en: 'Weight Percentage'
  },
  approvalTracking: {
    ar: 'تتبع طلبات الإعتماد',
    en: 'Approval Tracking'
  },
  approvalCycleType: {
    ar: 'نوع طلب الإعتماد',
    en: 'Approval Cycle Type'
  },
  waitingForApproval: {
    ar: 'بإنتظار إعتماد',
    en: 'Waiting for Approval'
  },
  yearFrom: {
    ar: 'من سنة',
    en: 'Year From'
  },
  yearTill: {
    ar: 'إلى سنة',
    en: 'Year Till'
  },
  link: {
    ar: 'ربط',
    en: 'Link'
  },
  unlink: {
    ar: 'إلغاء الربط',
    en: 'Unlink'
  },
  teamsManagement: {
    ar: 'إدارة الفرق',
    en: 'Teams Management'
  },
  teamManagement: {
    ar: 'إدارة الفريق',
    en: 'Team Management'
  },
  teamGoalsManagement: {
    ar: 'إدارة أهداف الفريق',
    en: 'Team Goals Management'
  },
  teamMembers: {
    ar: 'أعضاء الفريق',
    en: 'Team Members'
  },
  teamMembersCount: {
    ar: 'عدد أعضاء الفريق',
    en: 'Team Members Count'
  },
  manageTeamGoals: {
    ar: 'إدارة أهداف الفريق',
    en: 'Manage Team Goals'
  },
  teams: {
    ar: 'الفرق',
    en: 'Teams'
  },
  teamsGoals: {
    ar: 'أهداف الفرق',
    en: 'Teams Goals'
  },
  teamName: {
    ar: 'إسم الفريق',
    en: 'Team Name'
  },
  teamManagementConfiguration: {
    ar: 'إعدادات إدارة الفرق',
    en: 'Team Management Configuration'
  },
  teamPositions: {
    ar: 'أدوار الفرق',
    en: 'Team Positions'
  },
  teamRoles: {
    ar: 'أدوار الفرق',
    en: 'Team Positions'
  },
  teamTypes: {
    ar: 'أنواع الفرق',
    en: 'Team Types'
  },
  teamClassifications: {
    ar: 'تصنيفات الفرق',
    en: 'Team Classifications'
  },
  memberRole: {
    ar: 'دور العضو',
    en: 'Member Role'
  },
  teamGoalNameArabic: {
    ar: 'إسم هدف الفريق بالعربية',
    en: 'Team Goal Name in Arabic'
  },
  teamGoalTaskNameArabic: {
    ar: 'إسم مهمة هدف الفريق بالعربية',
    en: 'Team Goal Task Name in Arabic'
  },
  teamGoalNameEnglish: {
    ar: 'إسم هدف الفريق بالإنجليزية',
    en: 'Team Goal Name in English'
  },
  teamGoalTaskNameEnglish: {
    ar: 'إسم مهمة هدف الفريق بالإنجليزية',
    en: 'Team Goal Task Name in English'
  },
  goalTasks: {
    ar: 'مهام الهدف',
    en: 'Goal Tasks'
  },
  approveGoalsTasks: {
    ar: 'إعتماد الأهداف و المهام',
    en: 'Approve Goals and Tasks'
  },
  approveGoals: {
    ar: 'إعتماد الأهداف',
    en: 'Approve Goals'
  },
  approveTasksResults: {
    ar: 'إعتماد نتائج المهام',
    en: 'Approve Tasks Results'
  },
  tasksTeamGoals: {
    ar: 'مهام أهداف الفرق',
    en: 'Tasks Team Goals'
  },
  value: {
    ar: 'القيمة',
    en: 'Value'
  },
  numberOfSupportedDocuments: {
    ar: 'عدد المستندات',
    en: 'Number of Supported Documents'
  },
  resetPassword: {
    ar: 'إعادة تعيين كلمة المرور',
    en: 'Reset Password'
  },
  passwordRestedSuccesfully: {
    ar: 'تم إعادة تعيين كلمة المرور بنجاح',
    en: 'Password Rested Succesfully'
  },
  hierarchyVersion: {
    ar: 'إصدار الهيكلية',
    en: 'Hierarchy Version'
  },
  reports: {
    ar: 'التقارير',
    en: 'Reports'
  },
  email: {
    ar: 'البريد الإلكتروني',
    en: 'Email'
  },
  pending: {
    ar: 'قيد الانتظار',
    en: 'Pending'
  },
  open: {
    ar: 'قيد التحقق او العمل',
    en: 'Open'
  },
  closed: {
    ar: 'مغلقة',
    en: 'Closed'
  },
  invalid: {
    ar: 'غير صالحة',
    en: 'Invalid'
  },
  openTicketDate: {
    ar: 'تاريخ فتح التذكرة',
    en: 'Open Ticket Date'
  },
  reference: {
    ar: 'المرجع',
    en: 'Reference'
  },
  startChecking: {
    ar: 'بدء التحقق',
    en: 'Start Checking'
  },
  meetings: {
    ar: 'الاجتماعات',
    en: 'Meetings'
  },
  addMeeting: {
    ar: 'إضافة اجتماع',
    en: 'Add Meeting'
  },
  requiredField: {
    ar: 'الحقل مطلوب',
    en: 'Required Field'
  },
  thereIsNoAnyAgendasPressTheAddButtonToCreateNewAgenda: {
    ar: 'لا يوجد أي جداول أعمال، اضغط على زر إضافة لإنشاء جدول أعمال جديد',
    en: 'There is no any agendas, press the add button to create new agenda'
  },
  addTeam: {
    ar: 'إضافة فريق',
    en: 'Add Team'
  },
  teamDetails: {
    ar: 'تفاصيل الفريق',
    en: 'Team Details'
  },
  teamMainTasks: {
    ar: 'المهام الرئيسية للفريق',
    en: 'Team Main Tasks'
  },
  saveTeam: {
    ar: 'حفظ الفريق',
    en: 'Save Team'
  },
  teamType: {
    ar: 'نوع الفريق',
    en: 'Team Type'
  },
  teamClassification: {
    ar: 'تصنيف الفريق',
    en: 'Team Classification'
  },
  teamManager: {
    ar: 'مدير الفريق',
    en: 'Team Manager'
  },
  teamGoalArabic: {
    ar: 'هدف الفريق بالعربية',
    en: 'Team Goal in Arabic'
  },
  teamGoalEnglish: {
    ar: 'هدف الفريق بالإنجليزية',
    en: 'Team Goal in English'
  },
  teamLawArabic: {
    ar: 'القانون الذي ينطبق على الفريق بالعربية',
    en: 'Team Law in Arabic'
  },
  teamLawEnglish: {
    ar: 'القانون الذي ينطبق على الفريق بالإنجليزية',
    en: 'Team Law in English'
  },
  teamMainTaskNameArabic: {
    ar: 'إسم المهمة الرئيسية للفريق بالعربية',
    en: 'Team Main Task Name in Arabic'
  },
  teamMainTaskName: {
    ar: 'إسم المهمة الرئيسية للفريق بالإنجليزية',
    en: 'Team Main Task Name in English'
  },
  establishmentDate: {
    ar: 'تاريخ التأسيس',
    en: 'Establishment Date'
  },
  uploadedDocuments: {
    ar: 'المستندات المرفقة',
    en: 'Uploaded Documents'
  },
  apporvalRequests: {
    ar: 'طلبات الاعتماد',
    en: 'Approval Requests'
  },
  addTeamApprovalRequest: {
    ar: 'طلب اعتماد إضافة فريق',
    en: 'Add Team Approval Request'
  },
  refreshList: {
    ar: 'تحديث القائمة',
    en: 'Refresh List'
  },
  reason: {
    ar: 'السبب',
    en: 'Reason'
  },
  addReason: {
    ar: 'إضافة سبب',
    en: 'Add Reason'
  },
  targetReason: {
    ar: 'السببية للمستهدفات',
    en: 'Target Reason'
  },
  noReasonsMessage: {
    ar: 'لا يوجد أي سببية للمستهدفات',
    en: 'There is no any target reasons'
  },
  close: {
    ar: 'إغلاق',
    en: 'Close'
  },
  returnToHomePage: {
    ar: 'العودة للصفحة الرئيسية',
    en: 'Return to Home Page'
  },
  approvedSuccessfully: {
    ar: 'تم الاعتماد بنجاح',
    en: 'Approved Successfully'
  },
  rejectNoteTitle: {
    ar: 'عنوان ملاحظة الرفض',
    en: 'Reject Note Title'
  },
  newNoteTitleNameArabic: {
    ar: 'عنوان الملاحظة الجديدة بالعربية',
    en: 'New Note Title Name in Arabic'
  },
  newNoteTitleNameEnglish: {
    ar: 'عنوان الملاحظة الجديدة بالإنجليزية',
    en: 'New Note Title Name in English'
  },
  note: {
    ar: 'الملاحظة',
    en: 'Note'
  },
  thereShouldBeAtLeastOneImpactForTheProject: {
    ar: 'يجب أن يكون هناك أثر واحد على الأقل للمشروع',
    en: 'There should be at least one impact for the project'
  },
  thereShouldBeAtLeastOneImpactIfAProjectNotImplemented: {
    ar: 'يجب أن يكون هناك أثر واحد على الأقل إذا لم يتم تنفيذ المشروع',
    en: 'There should be at least one impact if a project not implemented'
  },
  thereShouldBeAtLeastOneRiskForTheProject: {
    ar: 'يجب أن يكون هناك خطر واحد على الأقل للمشروع',
    en: 'There should be at least one risk for the project'
  },
  thereShouldBeAtLeastOnealternativeSolutionForTheProject: {
    ar: 'يجب أن يكون هناك حل بديل واحد على الأقل للمشروع',
    en: 'There should be at least one alternative solution for the project'
  },
  newVersionAvailable: {
    ar: 'يوجد تحديث للنظام',
    en: 'New version available'
  },
  refresh: {
    ar: 'تحديث',
    en: 'Refresh'
  },
  addTeamSubTasks: {
    ar: 'إضافة مهام فرعية للفريق',
    en: 'Add Team Sub Tasks'
  },
  teamSubTasks: {
    ar: 'المهام الفرعية للفريق',
    en: 'Team Sub Tasks'
  },
  addTeamSubTask: {
    ar: 'إضافة مهمة فرعية للفريق',
    en: 'Add Team Sub Task'
  },
  teamMainTask: {
    ar: 'المهمة الرئيسية للفريق',
    en: 'Team Main Task'
  },
  responsibleTeamMember: {
    ar: 'العضو المسؤول عن المهمة',
    en: 'Responsible Team Member'
  },
  isMeasurable: {
    ar: 'قابلة للقياس',
    en: 'Is Measurable'
  },
  measurmentDate: {
    ar: 'تاريخ القياس',
    en: 'Measurment Date'
  },
  expectedStartDate: {
    ar: 'تاريخ البداية المتوقع',
    en: 'Expected Start Date'
  },
  expectedFinishDate: {
    ar: 'تاريخ الانتهاء المتوقع',
    en: 'Expected Finish Date'
  },
  youWillBeRedirectedToSubTasksPageForTeam: {
    ar: 'سيتم تحويلك إلى صفحة المهام الفرعية لفريق',
    en: 'You will be redirected to sub tasks page for team'
  },
  redirectWithOutWaiting: {
    ar: 'التحويل مباشرة',
    en: 'Redirect Now'
  },
  timeLeft: {
    ar: 'الوقت المتبقي',
    en: 'Time Left'
  },
  kpis: {
    ar: 'مؤشرات الأداء',
    en: 'KPIs'
  },
  projects: {
    ar: 'المشاريع',
    en: 'Projects'
  },
  kpiHaveThreeLevels: {
    ar: 'المؤشر يشمل ثلاثة مستويات',
    en: 'KPI have three levels'
  },
  kpiHaveTwoLevels: {
    ar: 'المؤشر يشمل مستويين',
    en: 'KPI have two levels'
  },
  kpi: {
    ar: 'المؤشر',
    en: 'KPI'
  },
  balancedScorecard: {
    ar: 'المحور',
    en: 'Balanced Scorecard'
  },
  strategyGoal: {
    ar: 'الهدف الاستراتيجي',
    en: 'Strategy Goal'
  },
  marchActual: {
    ar: 'القيمة الفعلية لشهر مارس',
    en: 'March Actual Value'
  },
  marchTarget: {
    ar: 'القيمة المستهدفة لشهر مارس',
    en: 'March Target Value'
  },
  juneActual: {
    ar: 'القيمة الفعلية لشهر يونيو',
    en: 'June Actual Value'
  },
  juneTarget: {
    ar: 'القيمة المستهدفة لشهر يونيو',
    en: 'June Target Value'
  },
  septemberActual: {
    ar: 'القيمة الفعلية لشهر سبتمبر',
    en: 'September Actual Value'
  },
  septemberTarget: {
    ar: 'القيمة المستهدفة لشهر سبتمبر',
    en: 'September Target Value'
  },
  decemberActual: {
    ar: 'القيمة الفعلية لشهر ديسمبر',
    en: 'December Actual Value'
  },
  decemberTarget: {
    ar: 'القيمة المستهدفة لشهر ديسمبر',
    en: 'December Target Value'
  },
  yearlyActual: {
    ar: 'القيمة الفعلية السنوية',
    en: 'Yearly Actual Value'
  },
  yearlyTarget: {
    ar: 'القيمة المستهدفة السنوية',
    en: 'Yearly Target Value'
  },
  yearlyActualForYear: {
    ar: 'القيمة الفعلية السنوية للسنة',
    en: 'Yearly Actual Value for year'
  },
  yearlyTargetForYear: {
    ar: 'القيمة المستهدفة السنوية للسنة',
    en: 'Yearly Target Value for year'
  },
  exportToExcel: {
    ar: 'تصدير إلى إكسل',
    en: 'Export to Excel'
  },
  reportIsEmpty: {
    ar: 'لا يوجد بيانات للتصدير',
    en: 'Report is empty'
  },
  generatingReport: {
    ar: 'جاري تصدير التقرير',
    en: 'Generating Report'
  },
  pleaseWaitWhiteTheReportIsBeingGenerated: {
    ar: 'الرجاء الانتظار حتى يتم إنشاء التقرير',
    en: 'Please wait while the report is being generated'
  },
  project: {
    ar: 'المشروع',
    en: 'Project'
  },
  actualStartDate: {
    ar: 'تاريخ البداية الفعلي',
    en: 'Actual Start Date'
  },
  actualFinishDate: {
    ar: 'تاريخ الانجاز الفعلي',
    en: 'Actual Finish Date'
  },
  expectedCompletionPercentage: {
    ar: 'النسبة المتوقعة للإنجاز',
    en: 'Expected Completion Percentage'
  },
  actualCompletionPercentage: {
    ar: 'النسبة الفعلية للإنجاز',
    en: 'Actual Completion Percentage'
  },
  budget: {
    ar: 'الميزانية',
    en: 'Budget'
  },
  numberOfTasks: {
    ar: 'عدد المهام',
    en: 'Number of Tasks'
  },
  hierarchyKpiAnalysis: {
    ar: 'تحليل المؤشرات على الهيكلية',
    en: 'Hierarchy KPI Analysis'
  },
  hierarchy: {
    ar: 'الهيكلية',
    en: 'Hierarchy'
  },
  assignedKpiCount: {
    ar: 'عدد المؤشرات المخصصة',
    en: 'Assigned KPI Count'
  },
  approveActualValueRequestCount: {
    ar: 'عدد طلبات اعتماد القيمة الفعلية',
    en: 'Approve Actual Value Request Count'
  },
  targetValuesNotEnteredCount: {
    ar: 'عدد القيم المستهدفة غير المدخلة',
    en: 'Target Values Not Entered Count'
  },
  waitingToAddActualValueCount: {
    ar: 'عدد القيم الفعلية غير المدخلة',
    en: 'Waiting To Add Actual Value Count'
  },
  approveTargetValuesRequestCount: {
    ar: 'عدد طلبات اعتماد القيم المستهدفة',
    en: 'Approve Target Values Request Count'
  },
  removeKpiAssignmentRequestCount: {
    ar: 'عدد طلبات إزالة المؤشر',
    en: 'Remove KPI Assignment Request Count'
  },
  numberOfKpisResultIsApproved: {
    ar: 'عدد المؤشرات التي تم اعتماد نتائجها',
    en: 'Number of KPIs Result is Approved'
  },
  numberOfKpisWillBeMeasuredBasedOnTheQuarter: {
    ar: 'عدد المؤشرات التي سيتم قياسها بناءً على الربع',
    en: 'Number of KPIs will be measured based on the quarter'
  },
  operationalPlanCompletionPercentage: {
    ar: 'نسبة إنجاز الخطة التشغيلية',
    en: 'Operational Plan Completion Percentage'
  },
  quarterCompletionResultsPercentage: {
    ar: 'نسبة إنجاز نتائج الربع',
    en: 'Quarter Completion Results Percentage'
  },
  missionUsedCount: {
    ar: 'عدد المهام المستخدمة',
    en: 'Mission Used Count'
  },
  missionTotalCount: {
    ar: 'عدد المهام الكلي',
    en: 'Mission Total Count'
  },
  missionUsedPercentage: {
    ar: 'نسبة المهام المستخدمة',
    en: 'Mission Used Percentage'
  },
  measurementEquation: {
    ar: 'معادلة القياس',
    en: 'Measurement Equation'
  },
  equationDescription: {
    ar: 'وصف المعادلة',
    en: 'Equation Description'
  },
  resultForYear: {
    ar: 'النتيجة لسنة',
    en: 'Result for Year'
  },
  evaluationForYear: {
    ar: 'التقييم لسنة',
    en: 'Evaluation for Year'
  },
  variableDescription: {
    ar: 'وصف المتغير',
    en: 'Variable Description'
  },
  trackKPIs: {
    ar: 'مؤشرات المسار',
    en: 'Track KPIs'
  },
  performanceAgreementsKPIs: {
    ar: 'مؤشرات اتفاقيات الأداء',
    en: 'Performance Agreements KPIs'
  },
  ticketCreatedBy: {
    ar: 'تم إنشاء التذكرة بواسطة',
    en: 'Ticket Created By'
  },
  ticketTitle: {
    ar: 'عنوان التذكرة',
    en: 'Ticket Title'
  },
  streamAndPerformanceAgreementKpis: {
    ar: 'مؤشرات المسار واتفاقيات الأداء',
    en: 'Stream And Performance Agreement Kpis'
  },
  manageStreams: {
    ar: 'إدارة المسارات',
    en: 'Manage Streams'
  },
  manageStreamKpis: {
    ar: 'إدارة مؤشرات المسار',
    en: 'Manage Stream Kpis'
  },
  managePerformanceAgreementKpis: {
    ar: 'إدارة مؤشرات اتفاقيات الأداء',
    en: 'Manage Performance Agreement Kpis'
  },
  addNewStream: {
    ar: 'إضافة مسار جديد',
    en: 'Add New Stream'
  },
  streams: {
    ar: 'المسارات',
    en: 'Streams'
  },
  addKpisToStream: {
    ar: 'إضافة مؤشرات للمسار',
    en: 'Add KPIs to Stream'
  },
  startYear: {
    ar: 'سنة البداية',
    en: 'Start Year'
  },
  endYear: {
    ar: 'سنة النهاية',
    en: 'End Year'
  },
  youWillBeRedirectedToTheStreamManageKpisPage: {
    ar: 'سيتم تحويلك إلى صفحة إدارة مؤشرات المسار',
    en: 'You will be redirected to the stream manage KPIs page'
  },
  sponsor: {
    ar: 'الراعي',
    en: 'Sponsor'
  },
  addKpis: {
    ar: 'إضافة مؤشرات',
    en: 'Add KPIs'
  },
  theKpis: {
    ar: 'المؤشرات',
    en: 'KPIs'
  },
  assignKpis: {
    ar: 'تعيين المؤشرات',
    en: 'Assign KPIs'
  },
  kpiName: {
    ar: 'اسم المؤشر',
    en: 'KPI Name'
  },
  pleaseSelectYear: {
    ar: 'الرجاء اختيار السنة',
    en: 'Please select year'
  },
  areYouSureYouWantToUnLink: {
    ar: 'هل أنت متأكد من أنك تريد إلغاء الربط؟',
    en: 'Are you sure you want to unlink?'
  },
  unlinkedSuccessfully: {
    ar: 'تم إلغاء الربط بنجاح',
    en: 'Unlinked Successfully'
  },
  linkedSuccessfully: {
    ar: 'تم الربط بنجاح',
    en: 'Linked Successfully'
  },
  notMasured: {
    ar: 'لم يتم القياس',
    en: 'Not Measured'
  },
  waitingApproval: {
    ar: 'قيد الاعتماد ',
    en: 'Waiting Approval'
  },
  approved: {
    ar: 'معتمد',
    en: 'Approved'
  },
  projectClassification: {
    ar: 'تصنيف المشروع',
    en: 'Project Classification'
  },
  projectStatus: {
    ar: 'حالة المشروع',
    en: 'Project Status'
  },
  projectSource: {
    ar: 'مصدر المشروع',
    en: 'Project Source'
  },
  addVariables: {
    ar: 'إضافة المتغيرات',
    en: 'Add Variables'
  },
  saveEquation: {
    ar: 'حفظ المعادلة',
    en: 'Save Equation'
  },
  notAllVariablesUsed: {
    ar: 'لم يتم استخدام جميع المتغيرات',
    en: 'Not all variables used'
  },
  requestSender: {
    ar: 'مرسل الطلب',
    en: 'Request Sender'
  },
  strategicOrMainOrSharedKpisCantBeDeleted: {
    ar: 'لا يمكن حذف المؤشرات الاستراتيجية أو الرئيسية أو المشتركة',
    en: 'Strategic or main or shared KPIs can\'t be deleted'
  },
  correctiveActions: {
    ar: 'الإجراءات التصحيحية',
    en: 'Corrective Actions'
  },
  performanceResults: {
    ar: 'نتائج الأداء',
    en: 'Performance Results'
  },
  previousYearsResults: {
    ar: 'نتائج السنوات السابقة',
    en: 'Previous Years Results'
  },
  evaluation: {
    ar: 'التقييم',
    en: 'Evaluation'
  },
  cannotDeleteKpiThatHasAssignments: {
    ar: 'لا يمكن حذف المؤشر الذي له تخصيصات',
    en: 'Cannot delete KPI that has assignments'
  },
  edit: {
    ar: 'تعديل',
    en: 'Edit'
  },
  customCalculationPeriod: {
    ar: 'دورية القياس مختلفة',
    en: 'Custom Calculation Period'
  },
  changeCalculationPeriodMessage: {
    ar: 'هل أنت متأكد من أنك تريد تغيير دورية القياس؟\nهذا يعني أن جميع القراءات السابقة سيتم حذفها.\nأي تقييم للأرباع السابفة سيتم إلغاؤها.',
    en: 'Are you sure you want to change calculation period?\nThis means all previous readings will be deleted.\nAny quarter evaluation will be not approved.'
  },
  targetAndActualYearlyValues: {
    ar: 'القيم المستهدفة والفعلية السنوية',
    en: 'Target and actual yearly values'
  },
  yearlyActualValue_Result: {
    ar: 'القيمة الفعلية السنوية / النتيجة',
    en: 'Yearly Actual Value / Result'
  },
  projectReleaseEvidence: {
    ar: 'دليل إطلاق المشروع',
    en: 'Project Release Evidence'
  },
  projectTaskBudgetEvidence: {
    ar: 'دليل ميزانية مهمة المشروع',
    en: 'Project Task Budget Evidence'
  },
  abilities: {
    ar: 'القدرات',
    en: 'Abilities'
  },
  addNewAbility: {
    ar: 'إضافة قدرة جديدة',
    en: 'Add New Ability'
  },
  excellenceKPIs: {
    ar: 'مؤشرات التميز',
    en: 'Excellence KPIs'
  },
  noMainStandardsUnderAxis: {
    ar: 'لا يوجد معايير رئيسية تحت المحور',
    en: 'No main standards under axis'
  },
  noSubStandardsUnderMainStandard: {
    ar: 'لا يوجد معايير فرعية تحت المعيار الرئيسي',
    en: 'No sub standards under main standard'
  },
  pressOnASubStandardToSeeItsAbilities: {
    ar: 'اضغط على معيار فرعي لمشاهدة قدراته',
    en: 'Press on a sub standard to see its abilities'
  },
  noAbilitiesForThisSubStandard: {
    ar: 'لا يوجد قدرات لهذا المعيار الفرعي',
    en: 'No abilities for this sub standard'
  },
  noAxisInThisYear: {
    ar: 'لا يوجد محاور في هذه السنة',
    en: 'No axis in this year'
  },
  pressOnAMainOrSubStandardToSeeItsKpis: {
    ar: 'اضغط على معيار رئيسي أو فرعي لمشاهدة مؤشراته',
    en: 'Press on a main or sub standard to see its KPIs'
  },
  axes: {
    ar: 'المحاور',
    en: 'Axes'
  },
  noData: {
    ar: 'لا يوجد بيانات',
    en: 'No Data'
  },
  manageMainProcesses: {
    ar: 'إدارة العمليات الرئيسية',
    en: 'Manage Main Processes'
  },
  manageSubProcesses: {
    ar: 'إدارة العمليات الفرعية',
    en: 'Manage Sub Processes'
  },
  manageSupportProcesses: {
    ar: 'إدارة العمليات المساندة',
    en: 'Manage Support Processes'
  },
  addNewProcess: {
    ar: 'إضافة عملية جديدة',
    en: 'Add New Process'
  },
  mainProcesses: {
    ar: 'العمليات الرئيسية',
    en: 'Main Processes'
  },
  subProcesses: {
    ar: 'العمليات الفرعية',
    en: 'Sub Processes'
  },
  supportProcesses: {
    ar: 'العمليات المساندة',
    en: 'Support Processes'
  },
  processesAndProcedures: {
    ar: 'العمليات والإجراءات',
    en: 'Processes And Procedures'
  },
  manageProcedures: {
    ar: 'إدارة الإجراءات',
    en: 'Manage Procedures'
  },
  manageProcedureKpis: {
    ar: 'إدارة مؤشرات الإجراءات',
    en: 'Manage Procedure KPIs'
  },
  addNewProcedure: {
    ar: 'إضافة إجراء جديد',
    en: 'Add New Procedure'
  },
  procedures: {
    ar: 'الإجراءات',
    en: 'Procedures'
  },
  process: {
    ar: 'العملية',
    en: 'Process'
  },
  mainProcess: {
    ar: 'العملية الرئيسية',
    en: 'Main Process'
  },
  processClassification: {
    ar: 'تصنيف العملية',
    en: 'Process Classification'
  },
  addKpisToProcedure: {
    ar: 'إضافة مؤشرات للإجراء',
    en: 'Add KPIs to Procedure'
  },
  youWillBeRedirectedToTheProcedureManageKpisPage: {
    ar: 'سيتم تحويلك إلى صفحة إدارة مؤشرات الإجراء',
    en: 'You will be redirected to the procedure manage KPIs page'
  },
  manageExcellenceWeights: {
    ar: 'إدارة أوزان التميز',
    en: 'Manage Excellence Weights'
  },
  axis: {
    ar: 'المحور',
    en: 'Axis'
  },
  manageWeights: {
    ar: 'إدارة الأوزان',
    en: 'Manage Weights'
  },
  totalWeightShouldBeEqualToHundred: {
    ar: 'مجموع الأوزان يجب أن يساوي 100',
    en: 'Total weight should be equal to 100'
  },
  thereIsNoAbilitiesAssignedToThisKpi: {
    ar: 'لا يوجد قدرات مخصصة لهذا المؤشر',
    en: 'There is no abilities assigned to this KPI'
  },
  abilityKpis: {
    ar: 'مؤشرات القدرة',
    en: 'Ability KPIs'
  },
  pressOnAnAbilityToSeeItsKpis: {
    ar: 'اضغط على قدرة لمشاهدة مؤشراته',
    en: 'Press on an ability to see its KPIs'
  },
  manageAbilityKpis: {
    ar: 'إدارة مؤشرات القدرة',
    en: 'Manage Ability KPIs'
  },
  addKpisToAbility: {
    ar: 'إضافة مؤشرات للقدرة',
    en: 'Add KPIs to Ability'
  },
  youWillBeRedirectedToTheAbilityManageKpisPage: {
    ar: 'سيتم تحويلك إلى صفحة إدارة مؤشرات القدرة',
    en: 'You will be redirected to the ability manage KPIs page'
  },
  switchLanguage: {
    ar: 'تغيير اللغة',
    en: 'Switch Language'
  },
  procedureOrProject: {
    ar: 'الإجراء / المشروع',
    en: 'Procedure / Project'
  },
  approvalList: {
    ar: 'قائمة الاعتماد',
    en: 'Approval List'
  },
  kpiLinkedToProcedureRemoveLinkFirst: {
    ar: 'المؤشر مرتبط بإجراء، يجب إزالة الربط أولاً',
    en: 'KPI linked to procedure, remove link first'
  },
  addNewTask: {
    ar: 'إضافة مهمة جديدة',
    en: 'Add New Task'
  },
  manageTasksAndAssignments: {
    ar: 'إدارة المهام والتكليفات',
    en: 'Manage Tasks And Assignments'
  },
  taskMainDetails: {
    ar: 'التفاصيل الرئيسية للمهمة',
    en: 'Task Main Details'
  },
  timeFrame: {
    ar: 'الإطار الزمني',
    en: 'Time Frame'
  },
  isTaskLinkedToAMainTask: {
    ar: 'هل المهمة مرتبطة بمهمة رئيسية؟',
    en: 'Is task linked to a main task?'
  },
  isTaskLinkedToAProject: {
    ar: 'هل المهمة مرتبطة بمشروع؟',
    en: 'Is task linked to a project?'
  },
  summary: {
    ar: 'الملخص',
    en: 'Summary'
  },
  taskOwner: {
    ar: 'مالك المهمة',
    en: 'Task Owner'
  },
  taskResponsibleEmployee: {
    ar: 'الموظف المسؤول عن المهمة',
    en: 'Responsible Employee For Task'
  },
  taskClassification: {
    ar: 'تصنيف المهمة',
    en: 'Task Classification'
  },
  taskStatus: {
    ar: 'حالة المهمة',
    en: 'Task Status'
  },
  taskPriority: {
    ar: 'أولوية المهمة',
    en: 'Task Priority'
  },
  autoCalculateExpectedFinishDate: {
    ar: 'حساب تاريخ الانتهاء المتوقع تلقائيًا',
    en: 'Auto Calculate Expected Finish Date'
  },
  durationWorkingDays: {
    ar: 'المدة (أيام عمل)',
    en: 'Duration (Working Days)'
  },
  filesAndDocuments: {
    ar: 'الملفات والمستندات',
    en: 'Files And Documents'
  },
  fileName: {
    ar: 'اسم الملف',
    en: 'File Name'
  },
  projectName: {
    ar: 'اسم المشروع',
    en: 'Project Name'
  },
  fileFormat: {
    ar: 'صيغة الملف',
    en: 'File Format'
  },
  selectOneValue: {
    ar: 'اختر قيمة واحدة',
    en: 'Select one value'
  },
  taskName: {
    ar: 'اسم المهمة',
    en: 'Task Name'
  },
  selectedProject: {
    ar: 'المشروع المختار',
    en: 'Selected Project'
  },
  taskCreatedSuccessfully: {
    ar: 'تم إنشاء المهمة بنجاح',
    en: 'Task created successfully'
  },
  manageTasks: {
    ar: 'إدارة المهام',
    en: 'Manage Tasks'
  },
  tasks: {
    ar: 'المهام',
    en: 'Tasks'
  },
  addSubTask: {
    ar: 'إضافة مهمة فرعية',
    en: 'Add Sub Task'
  },
  youWillBeRedirectedToAddTaskPageUnderTheFollowingTask: {
    ar: 'سيتم تحويلك إلى صفحة إضافة مهمة تحت المهمة التالية',
    en: 'You will be redirected to add task page under the following task'
  },
  parentTask: {
    ar: 'المهمة الأساسية',
    en: 'Parent Task'
  },
  editTask: {
    ar: 'تعديل المهمة',
    en: 'Edit Task'
  },
  descriptionArabic: {
    ar: 'الوصف بالعربية',
    en: 'Description in Arabic'
  },
  descriptionEnglish: {
    ar: 'الوصف بالإنجليزية',
    en: 'Description in English'
  },
  searchCommand: {
    ar: 'إبحث',
    en: 'Search'
  },
  taskChanges: {
    ar: 'تغييرات المهمة',
    en: 'Task Changes'
  },
  noChangesMade: {
    ar: 'لم يتم إجراء أي تغييرات',
    en: 'No changes made'
  },
  oldValueLabel: {
    ar: 'القيمة القديمة',
    en: 'Old Value'
  },
  newValueLabel: {
    ar: 'القيمة الجديدة',
    en: 'New Value'
  },
  taskCreated: {
    ar: 'تم إنشاء المهمة',
    en: 'Task Created'
  },
  initialTaskCreation: {
    ar: 'إنشاء المهمة الأولية',
    en: 'Initial Task Creation'
  },
  taskFiles: {
    ar: 'ملفات المهمة',
    en: 'Task Files'
  },
  manageMyTasks: {
    ar: 'إدارة مهامي',
    en: 'Manage My Tasks'
  },
  myTasks: {
    ar: 'مهامي',
    en: 'My Tasks'
  },
  acceptAndStartTask: {
    ar: 'قبول وبدء المهمة',
    en: 'Accept and Start Task'
  },
  markTaskDone: {
    ar: 'تحديد المهمة كمنجزة',
    en: 'Mark Task Done'
  },
  requestTaskModification: {
    ar: 'طلب تعديل المهمة',
    en: 'Request Task Modification'
  },
  rejectTask: {
    ar: 'رفض المهمة',
    en: 'Reject Task'
  },
  viewTask: {
    ar: 'عرض المهمة',
    en: 'View Task'
  },
  areYouSureYouWantToAcceptAndStartTheTask: {
    ar: 'هل أنت متأكد من أنك تريد قبول وبدء المهمة؟',
    en: 'Are you sure you want to accept and start the task?'
  },
  taskStarted: {
    ar: 'تم بدء المهمة',
    en: 'Task Started'
  },
  taskEvidenveCompletionFiles: {
    ar: 'ملفات إثبات إكمال المهمة',
    en: 'Task Evidence Completion Files'
  },
  pleaseUploadTaskEvidenceCompletionFilesOrProvideOutput: {
    ar: 'الرجاء تحميل ملفات إثبات إكمال المهمة أو تقديم مخرج',
    en: 'Please upload task evidence completion files or provide output'
  },
  addtaskEvidenveCompletionFilesThenSendTaskForApproval: {
    ar: 'الرجاء تحميل ملفات إثبات إكمال المهمة ثم إرسال المهمة للاعتماد',
    en: 'Please upload task evidence completion files then send task for approval'
  },
  sendModificationRequest: {
    ar: 'إرسال طلب التعديل',
    en: 'Send Modification Request'
  },
  sendRejectionRequest: {
    ar: 'إرسال طلب الرفض',
    en: 'Send Rejection Request'
  },
  tasksApprovalCycleStatus: {
    ar: 'حالة خط سير اعتماد المهام',
    en: 'Tasks Approval Cycle Status'
  },
  myTasksApprovalCycleStatus: {
    ar: 'حالة خط سير اعتماد مهامي',
    en: 'My Tasks Approval Cycle Status'
  },
  tasksDashboard: {
    ar: 'لوحة بيانات المهام',
    en: 'Tasks Dashboard'
  },
  pendingApprovalFrom: {
    ar: 'قيد الانتظار للاعتماد من',
    en: 'Pending approval from'
  },
  concernedParty: {
    ar: 'الجهة المعنية',
    en: 'Concerned Party'
  },
  approveTasks: {
    ar: 'اعتماد المهام',
    en: 'Approve Tasks'
  },
  saveAndReject: {
    ar: 'حفظ ورفض',
    en: 'Save and Reject'
  },
  noNotesMessage: {
    ar: 'لا توجد ملاحظات',
    en: 'No notes'
  },
  tasksOpenAndClosed: {
    ar: 'المهام المفتوحة والمغلقة',
    en: 'Open and Closed Tasks'
  },
  closedTasksTimeStatistics: {
    ar: 'إحصائيات الوقت للمهام المغلقة',
    en: 'Closed Tasks Time Statistics'
  },
  openTasksBasedOnPriority: {
    ar: 'المهام المفتوحة بناءً على الأولوية',
    en: 'Open Tasks Based On Priority'
  },
  percentageCommitmentCompletingTasksWithinSpecifiedTime: {
    ar: 'نسبة الالتزام بإغلاق المهام ضمن الوقت المحدد',
    en: 'Percentage of commitment to complete tasks within the specified time'
  },
  percentageComplianceWithApprovedDeadline: {
    ar: 'نسبة الالتزام للموعد المعتمد (قبل التمديد)',
    en: 'Percentage compliance with approved deadline'
  },
  numberOfAssignedTasks: {
    ar: 'عدد المهام الموكلة',
    en: 'Number of assigned tasks'
  },
  tasksProductivity: {
    ar: 'إنتاجية المهام',
    en: 'Tasks Productivity'
  },
  distributionOfOpenTasksAccordingToClassification: {
    ar: 'توزيع المهام المفتوحة حسب التصنيف',
    en: 'Distribution of open tasks according to classification'
  },
  generalSecretaryOffice: {
    ar: 'مكتب الأمين العام',
    en: 'General Secretary Office'
  },
  areYouSureYouWantToApprove: {
    ar: 'هل أنت متأكد من أنك تريد الاعتماد؟',
    en: 'Are you sure you want to approve?'
  },
  submitRatting: {
    ar: 'إرسال التقييم',
    en: 'Submit Ratting'
  },
  howWellTheTaskWasClosed: {
    ar: 'ما مدى جودة اغلاق المهمة؟',
    en: 'How well the task was closed?'
  },
  mainTasksDashboard: {
    ar: 'لوحة بيانات المهام الرئيسية',
    en: 'Main Tasks Dashboard'
  },
  leaderboard: {
    ar: 'لوحة المتصدرين',
    en: 'Leaderboard'
  },
  youCanSelectMoreThanOne: {
    ar: 'يمكنك اختيار أكثر من قيمة',
    en: 'You can select more than one'
  },
  projectsAndDocuments: {
    ar: 'المشاريع والمستندات',
    en: 'Projects and Documents'
  },
  output: {
    ar: 'المخرج',
    en: 'Output'
  },
  outputOrFilesRequired: {
    ar: 'الرجاء إدخال مخرج أو ملف واحد على الأقل',
    en: 'At least one of the items (files or output) is required.'
  },
  finishTaskOnTime: {
    ar: 'إنهاء المهمة في الوقت المحدد',
    en: 'Finish Task On Time'
  },
  outputQuality: {
    ar: 'جودة المخرجات',
    en: 'Output Quality'
  },
  overallSatisfaction: {
    ar: 'الرضا العام',
    en: 'Overall Satisfaction'
  },
  taskDescription: {
    ar: 'وصف المهمة',
    en: 'Task Description'
  },
  ratingResult: {
    ar: 'نتيجة التقييم',
    en: 'Rating Result'
  },
  taskOutput: {
    ar: 'مخرج المهمة',
    en: 'Task Output'
  },
  taskProjects: {
    ar: 'مشاريع المهمة',
    en: 'Task Projects'
  },
  tasksAndAssignmentsSystem: {
    ar: 'نظام المهام والتكليفات',
    en: 'Tasks and Assignments System'
  },
  startTask: {
    ar: 'بدء المهمة',
    en: 'Start Task'
  },
  closeDate: {
    ar: 'تاريخ الإغلاق',
    en: 'Close Date'
  },
  remainingDaysToTaskFinishDate: {
    ar: 'الأيام المتبقية لتاريخ الإنتهاء',
    en: 'Remaining Days to Task Finish Date'
  },
  editOutput: {
    ar: 'تعديل المخرج',
    en: 'Edit Output'
  },
  expectedFinishTime: {
    ar: 'الوقت المتوقع للإنتهاء',
    en: 'Expected Finish Time'
  },
  timeShouldBeBetween6AM_AND4PM: {
    ar: 'يجب أن يكون الوقت بين الساعة 6:00 صباحًا و4:00 مساءً',
    en: 'Time should be between 6:00 AM and 4:00 PM'
  },
  forgetPassword_Q: {
    ar: 'نسيت كلمة المرور؟',
    en: 'Forgot Password?'
  },
  forgetPassword: {
    ar: 'نسيت كلمة المرور',
    en: 'Forgot Password'
  },
  forgetPasswordModalMessageStepOne: {
    ar: 'الرجاء إدخال الجزء الموجود قبل الرمز \'@\' لعنوان بريدك الإلكتروني (على سبيل المثال، بالنسبة إلى email@awqafdubai.gov.ae أدخل \'email\').',
    en: 'Please enter the part before the \'@\' symbol of your email address (e.g., for email@awqafdubai.gov.ae, enter \'email\').'
  },
  forgetPasswordModalMessageStepTwo: {
    ar: 'لقد أرسلنا كلمة مرور لمرة واحدة (OTP) إلى بريدك الإلكتروني المسجل. الرجاء إدخال كلمة مرور لمرة واحدة أدناه:',
    en: 'We have sent a one-time password (OTP) to your registered email. Please enter the OTP below:'
  },
  forgetPasswordModalMessageStepThree: {
    ar: 'أدخل وتأكيد كلمة المرور الجديدة الخاصة بك.',
    en: 'Enter and confirm your new password.'
  },
  enterYourUsername: {
    ar: 'أدخل اسم المستخدم الخاص بك',
    en: 'Enter your username'
  },
  enterOTP: {
    ar: 'أدخل كلمة المرور المرسلة إلى بريدك الإلكتروني',
    en: 'Enter OTP'
  },
  newPassword: {
    ar: 'كلمة المرور الجديدة',
    en: 'New Password'
  },
  confirmPassword: {
    ar: 'تأكيد كلمة المرور',
    en: 'Confirm Password'
  },
  sendOTP: {
    ar: 'أرسل كلمة مرور لمرة واحدة',
    en: 'Send OTP'
  },
  validateOTP: {
    ar: 'التحقق من صحة OTP',
    en: 'Validate OTP'
  },
  updatePassword: {
    ar: 'تحديث كلمة المرور',
    en: 'Update Password'
  },
  isTaskExtended: {
    ar: 'هل تم تمديد المهمة',
    en: 'Is task extended'
  },
  taskShouldBeFinishedBeforeHour: {
    ar: 'يجب أن تنتهي المهمة قبل الساعة',
    en: 'Task should be finished before hour'
  },
  taskExtended: {
    ar: 'المهمة الممددة',
    en: 'Task Extended'
  },
  tasksReport: {
    ar: 'تقرير المهام',
    en: 'Tasks Report'
  },
  exportToPDF: {
    ar: 'تصدير إلى PDF',
    en: 'Export to PDF'
  },
  taskAndAssignmentsReport: {
    ar: 'تقرير المهام والتكليفات',
    en: 'Tasks and Assignments Report'
  },
  departmentName: {
    ar: 'اسم الإدراة',
    en: 'Department Name'
  },
  numberOfDoneTasks: {
    ar: 'عدد المهام المنجزة',
    en: 'Number of Done Tasks'
  },
  percentageOfCompletion: {
    ar: 'نسبة الإنجاز',
    en: 'Percentage of Completion'
  },
  allDepartments: {
    ar: 'كافة الإدارات',
    en: 'All Departments'
  },
  tasksByStatus: {
    ar: 'المهام حسب الحالة',
    en: 'Tasks By Status'
  },
  tasksByPriority: {
    ar: 'المهام حسب الأولوية',
    en: 'Tasks By Priority'
  },
  departmentsParticipationPercentage: {
    ar: 'نسبة مشاركة الإدارات',
    en: 'Departments Participation Percentage'
  },
  efficiencyInCompletingTasks: {
    ar: 'كفاءة إنجاز المهام',
    en: 'Efficiency in completing tasks'
  },
  averageNumberOfDaysOfCompletion: {
    ar: 'متوسط عدد أيام الإنجاز',
    en: 'Average number of days of completion'
  },
  numberOfClosedTasks: {
    ar: 'عدد المهام المغلقة',
    en: 'Number of closed tasks'
  },
  numberOfPendingTasks: {
    ar: 'عدد المهام قيد الانجاز',
    en: 'Number of pending tasks'
  }
}

export const Strings =
  [
    {
      id: "PLEASE_CHOOSE_YEAR", values: {
        ar: 'الرجاء اختيار السنة',
        en: 'Please choose year'
      }
    },
    {
      id: "PLEASE_CHOOSE_HIERARCHY", values: {
        ar: 'الرجاء اختيار مستوى الهيكلية',
        en: 'Please choose hierarchy'
      }
    },
    {
      id: "REQUIRED_FIELD", values: {
        ar: 'الحقل مطلوب',
        en: 'Required field'
      }
    },
    {
      id: "WEEK_DAYS_ERROR", values: {
        ar: 'لا يمكن إختيار أيام السبت و الأحد',
        en: 'Can\'t Choose Saturdays and Sundays'
      }
    },
    {
      id: "PLEASE_UPLOAD_SUPPORTED_DOCUMENT_AND_THEN_ADD_ACTUAL_VALUE", values: {
        ar: ' الرجاء إرفاق المستند الثبوتي ثم إضافة القيمة الفعلية',
        en: 'Please upload supported document and then add actual value '
      }
    },
    {
      id: "VALUE_GREATER_THAN_OR_EQUAL", values: {
        ar: ' القيمة يجب أن تكون أكبر من أو تساوي ',
        en: ' Value should be greater than '
      }
    },
    {
      id: "VALUE_LESS_THAN_OR_EQUAL", values: {
        ar: ' القيمة يجب أن تكون أصغر من أو تساوي ',
        en: ' Value should be less than or equal '
      }
    },
    {
      id: "ARABIC_TEXT_ONLY", values: {
        ar: ' الرجاء الإدخال باللغة العربية فقط',
        en: ' Please enter arabic text only '
      }
    },
    {
      id: "ENGLISH_TEXT_ONLY", values: {
        ar: ' الرجاء الإدخال باللغة الإنجليزية فقط',
        en: ' Please enter english text only '
      }
    },
    {
      id: "SYMBOLS_NOT_ALLOWED", values: {
        ar: ' الرجاء الإدخال بدون أي رمز',
        en: ' Please enter without any symbol '
      }
    },
    {
      id: "NOT_ONLY_NUMBERS", values: {
        ar: " الرجاء عدم إدخال فقط أرقام",
        en: " Please don\'t enter only numbers "
      }
    },
    {
      id: "MUST_UPLOAD_AT_LEASET_ONE_FILE", values: {
        ar: " يجب إرفاق ملف واحد على الأقل",
        en: " Must upload at least one file "
      }
    }
  ]

/**
 * name
 */
export function checkLanguage(locale: string, arabicValue: string, englishValue): string {
  return locale == 'ar-AE' ? arabicValue : englishValue;
}

export function checkLanguageAdv(locale: string, obj): string {
  return locale == 'ar-AE' ? obj.ar : obj.en;
}






import { BaseEntity } from "./base/base-entity.model";
import { Department } from "./departmentModel";
import { Section } from "./sectionModel";
import { Sector } from "./sectorModel";

export class MapUser extends BaseEntity {
    constructor() {
        super();
    }
    on: string;
    onId: string;
    userId: number;
    section: Section;
    department: Department;
    sector: Sector;
}
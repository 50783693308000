<!-- ============================================================== -->
<!-- Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->
<div class="page-breadcrumb">
    <div class="row">
        <div class="col-5 align-self-center">
            <h4 class="page-title">{{locale == 'ar-AE' ? pageInfo?.titleAr : pageInfo?.title}}</h4>
        </div>
        <div class="col-7 align-self-center">
            <div class="d-flex align-items-center justify-content-end">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                            <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                                <a href='javascript:void(0)'>{{locale == 'ar-AE' ? url.titleAr : url.title}}</a>
                            </li>
                            <li class="breadcrumb-item active" *ngIf="last">{{locale == 'ar-AE' ? url.titleAr : url.title}}</li>
                        </ng-template>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- ============================================================== -->
<!-- End Bread crumb and right sidebar toggle -->
<!-- ============================================================== -->

import { Injectable } from '@angular/core';
import { Http, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Rx';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ServiceBase } from './service.base';
import { ServiceUrls } from '../common/service-urls';
import { SectorResponse } from '../model/response/sectorResponse';
import { StrategyGoalResponse } from '../model/response/strategyGoalResponse';
import { BalancedScorecardResponse } from '../model/response/balancedScorecardResponse';
import { SWOTSectionResponse } from '../model/response/swotSectionResponse';
import { SectorGoalRequest } from '../model/request/sectorGoalRequest';
import { SectorGoalResponse } from '../model/response/sectorGoalResponse';
import { DepartmentResponse } from '../model/response/departmentResponse';
import { DepartmentGoalRequest } from '../model/request/departmentGoalRequest';
import { DepartmentGoalResponse } from '../model/response/departmentGoalResponse';
import { AggregationMethodResponse } from '../model/response/aggregationMethodResponse';
import { CalculationPreiodResponse } from '../model/response/calculationPreiodResponse';
import { ValueTypeResponse } from '../model/response/valueTypeResponse';
import { SectionKpiRequest } from '../model/request/sectionKpiRequest';
import { SectionResponse } from '../model/response/sectionResponse';
import { SectionKpiResponse } from '../model/response/sectionKpiResponse';
import { AggregationMethodRequest } from '../model/request/aggregationMethodRequest';
import { CalculationPeriodRequest } from '../model/request/calculationPeriodRequest';
import { ValueTypeRequest } from '../model/request/valueTypeRequest';
import { ClassificationRequest } from '../model/request/classificationRequest';
import { ClassificationResponse } from '../model/response/classificationResponse';
import { StatusRequest } from '../model/request/statusRequest';
import { StatusResponse } from '../model/response/statusResponse';
import { TrendResponse } from '../model/response/trendResponse';
import { TrendRequest } from '../model/request/trendRequest';
import { KpiGenericRequest } from '../model/request/kpiGenericRequest';
import { KpiGenericResponse } from '../model/response/kpiGenericResponse';
import { forkJoin } from 'rxjs';
import { SubclassificationRequest } from '../model/request/subclassificationRequest';
import { SubclassificationResponse } from '../model/response/subclassificationResponse';
import { KpiAssignRequest } from '../model/request/kpiAssignRequest';
import { KpiAssignResponse } from '../model/response/kpiAssignResponse';


@Injectable()
export class PerformanceService extends ServiceBase {

    protected options: RequestOptions;


    constructor(protected http: Http) {
        super(http);

    }

    Get(stringUrl): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    Create(request, stringUrl): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, request, this.options);
    }

    Delete(request, stringUrl): Observable<any> {
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam(request);
        const opt = { headers: this.headers, body: request }
        return this.http.delete(url, opt);
    }

    Update(request, stringUrl): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, request);
    }

    GetSectors(): Observable<SectorResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Sector}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetStrategyGoals(): Observable<StrategyGoalResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.StrategyGoal}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetBalancedScorecards(): Observable<BalancedScorecardResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.BalancedScorecard}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetSWOTs(): Observable<SWOTSectionResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.SectorSWOT}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    CreateSectorGoal(sectorGoalRequest: SectorGoalRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.SectorGoal}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, sectorGoalRequest, this.options);
    }

    GetDepartments(): Observable<DepartmentResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Department}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetSectorGoals(): Observable<SectorGoalResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.SectorGoal}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    CreateDepartmentGoal(departmentGoalRequest: DepartmentGoalRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.DepartmentGoal}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, departmentGoalRequest, this.options);
    }

    GetSections(): Observable<SectionResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Section}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetDepartmentGoals(): Observable<DepartmentGoalResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.DepartmentGoal}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetAggregationMethods(): Observable<AggregationMethodResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.AggregationMethod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetCalculationPeriods(): Observable<CalculationPreiodResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.CalculationPeriod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetValueTypes(): Observable<ValueTypeResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.ValueType}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    CreateSectionKpi(sectionKpiRequest: SectionKpiRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.SectionKpi}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, sectionKpiRequest, this.options);
    }

    CreateAggregationMethod(aggregationMethodRequest: AggregationMethodRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.AggregationMethod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, aggregationMethodRequest, this.options);
    }

    DeleteAggregationMethod(aggregationMethodRequest: AggregationMethodRequest): Observable<any> {
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.AggregationMethod}`;
        let params: URLSearchParams = this.SetUrlParam(aggregationMethodRequest);
        const opt = { headers: this.headers, body: aggregationMethodRequest }
        return this.http.delete(url, opt);
    }

    UpdateAggregationMethod(aggregationMethodRequest: AggregationMethodRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.AggregationMethod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, aggregationMethodRequest);
    }

    CreateCalculationPeriod(calculationPeriodRequest: CalculationPeriodRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.CalculationPeriod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, calculationPeriodRequest, this.options);
    }

    DeleteCalculationPeriod(calculationPeriodRequest: CalculationPeriodRequest): Observable<any> {
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.CalculationPeriod}`;
        let params: URLSearchParams = this.SetUrlParam(calculationPeriodRequest);
        const opt = { headers: this.headers, body: calculationPeriodRequest }
        return this.http.delete(url, opt);
    }

    UpdateCalculationPeriod(calculationPeriodRequest: CalculationPeriodRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.CalculationPeriod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, calculationPeriodRequest);
    }

    CreateValueType(valueTypeRequest: ValueTypeRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.ValueType}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, valueTypeRequest, this.options);
    }

    DeleteValueType(valueTypeRequest: ValueTypeRequest): Observable<any> {
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.ValueType}`;
        let params: URLSearchParams = this.SetUrlParam(valueTypeRequest);
        const opt = { headers: this.headers, body: valueTypeRequest }
        return this.http.delete(url, opt);
    }

    UpdateValueType(valueTypeRequest: ValueTypeRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.ValueType}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, valueTypeRequest);
    }

    CreateClassification(classificationRequest: ClassificationRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Classification}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, classificationRequest, this.options);
    }

    DeleteClassification(classificationRequest: ClassificationRequest): Observable<any> {
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Classification}`;
        let params: URLSearchParams = this.SetUrlParam(classificationRequest);
        const opt = { headers: this.headers, body: classificationRequest }
        return this.http.delete(url, opt);
    }

    UpdateClassification(classificationRequest: ClassificationRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Classification}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, classificationRequest);
    }

    GetClassifications(): Observable<ClassificationResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Classification}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    CreateStatus(statusRequest: StatusRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Status}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, statusRequest, this.options);
    }

    DeleteStatus(statusRequest: StatusRequest): Observable<any> {
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Status}`;
        let params: URLSearchParams = this.SetUrlParam(statusRequest);
        const opt = { headers: this.headers, body: statusRequest }
        return this.http.delete(url, opt);
    }

    UpdateStatus(statusRequest: StatusRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Status}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, statusRequest);
    }

    GetStatuses(): Observable<StatusResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Status}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    CreateTrend(trendRequest: TrendRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Trend}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, trendRequest, this.options);
    }

    DeleteTrend(trendRequest: TrendRequest): Observable<any> {
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Trend}`;
        let params: URLSearchParams = this.SetUrlParam(trendRequest);
        const opt = { headers: this.headers, body: trendRequest }
        return this.http.delete(url, opt);
    }

    UpdateTrend(trendRequest: TrendRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Trend}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, trendRequest);
    }

    GetTrends(): Observable<TrendResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Trend}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    CreateSubclassification(subclassificationRequest: SubclassificationRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Subclassification}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, subclassificationRequest, this.options);
    }

    DeleteSubclassification(subclassificationRequest: SubclassificationRequest): Observable<any> {
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Subclassification}`;
        let params: URLSearchParams = this.SetUrlParam(subclassificationRequest);
        const opt = { headers: this.headers, body: subclassificationRequest }
        return this.http.delete(url, opt);
    }

    UpdateSubclassification(subclassificationRequest: SubclassificationRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Subclassification}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, subclassificationRequest);
    }

    GetSubclassification(): Observable<SubclassificationResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Subclassification}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    // CreateKpi(kpiRequest: KpiGenericRequest): Observable<any> {
    //     this.SetHeader(true);
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Kpi.AddKpi}`;
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     return this.http.post(url, kpiRequest, this.options);
    // }

    // DeleteKpi(kpiRequest: KpiGenericRequest): Observable<any> {
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Kpi.DeleteKpi}`;
    //     let params: URLSearchParams = this.SetUrlParam(kpiRequest);
    //     const opt = { headers: this.headers, body: kpiRequest }
    //     return this.http.delete(url, opt);
    // }

    // UpdateKpi(kpiRequest: KpiGenericRequest): Observable<any> {
    //     this.SetHeader(true);
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Kpi.UpdateKpi}`;
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     return this.Put(url, kpiRequest);
    // }

    // GetKpis(): Observable<KpiGenericResponse> {
    //     this.SetHeader(true);
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Kpi.KpiGetAll}`;
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     return this.GetData(url);
    // }

    // CreateKpiAssign(kpiAssignRequest: KpiAssignRequest): Observable<any> {
    //     this.SetHeader(true);
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.KpiAssign}`;
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     return this.http.post(url, kpiAssignRequest, this.options);
    // }

    // DeleteKpiAssign(kpiAssignRequest: KpiAssignRequest): Observable<any> {
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.KpiAssign}`;
    //     let params: URLSearchParams = this.SetUrlParam(kpiAssignRequest);
    //     const opt = { headers: this.headers, body: kpiAssignRequest }
    //     return this.http.delete(url, opt);
    // }

    // UpdateKpiAssign(kpiAssignRequest: KpiAssignRequest): Observable<any> {
    //     this.SetHeader(true);
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.KpiAssign}`;
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     return this.Put(url, kpiAssignRequest);
    // }

    // GetKpisAssign(): Observable<KpiAssignResponse> {
    //     this.SetHeader(true);
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.KpiAssign}`;
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     return this.GetData(url);
    // }

    GetSectionKpi(): Observable<SectionKpiResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.SectionKpi}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    // GetKpiNotAssigned(sectionId: Number): Observable<KpiGenericResponse> {
    //     this.SetHeader(true);
    //     const url = `${this.serviceURL + ServiceUrls.performanceManagementGroup.Kpi.KpiNotAssigned + "/" + sectionId}`;
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     return this.GetData(url);
    // }

    // GetDataForAddKpi(): Observable<any> {
    //     this.SetHeader(true);
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     const response0 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.AggregationMethod}`);
    //     const response1 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.CalculationPeriod}`);
    //     const response2 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.ValueType}`);
    //     const response3 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Classification}`);
    //     const response4 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Trend}`);
    //     const response5 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Status}`);
    //     const response6 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Subclassification}`);
    //     const response7 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Kpi.KpiGetAll}`);
    //     return forkJoin([response0, response1, response2, response3, response4, response5, response6, response7]);
    // }

    GetDataForAssignKpi(): Observable<any> {
        this.SetHeader(true);
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        const response0 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.AggregationMethod}`);
        const response1 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.CalculationPeriod}`);
        const response2 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.ValueType}`);
        const response3 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Classification}`);
        const response4 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Trend}`);
        const response5 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Status}`);
        const response6 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Subclassification}`);
        // const response7 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Kpi.KpiNotAssigned}`);
        return forkJoin([response0, response1, response2, response3, response4, response5, response6]);
    }

    GetDataGeneric(urls: String[]): Observable<any> {
        this.SetHeader(true);
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });

        const arrayOfResponses = [];
        urls.forEach(element => {
            arrayOfResponses.push(this.GetData(`${this.serviceURL + element}`))
        });

        return forkJoin(arrayOfResponses);
    }

    CreateBulk(requests: any[], urls: string[]): Observable<any> {
        this.SetHeader(true);

        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });

        const arrayOfResponses = [];
        urls.forEach((obj, index) => {
            const url = `${this.serviceURL + obj}`;
            arrayOfResponses.push(this.Create(requests[index], obj));
        })

        return forkJoin(arrayOfResponses);
    }

    // GetDataForOnChangeKpi(): Observable<any> {
    //     this.SetHeader(true);
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     const response0 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Classification}`);
    //     const response1 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Kpi.KpiGetAll}`);
    //     return forkJoin([response0, response1]);
    // }

    // GetDataAssignKpi():Observable<any>{
    //     this.SetHeader(true);
    //     let params: URLSearchParams = this.SetUrlParam("");
    //     this.options = new RequestOptions({ headers: this.headers, search: params });
    //     const response0 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Sector}`);
    //     const response1 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Department}`);
    //     const response2 = this.GetData(`${this.serviceURL + ServiceUrls.performanceManagementGroup.Section}`);


    // }
}
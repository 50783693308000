import { Injectable } from '@angular/core';
import { Headers, Http, Response, RequestOptions, URLSearchParams, ResponseOptions } from '@angular/http';
import { Observable } from 'rxjs/Rx';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ServiceBase } from './service.base';
import { ServiceUrls } from '../common/service-urls';

import { SWOTSectionRequest, SWOTSectionSearchRequest } from '../model/request/swotSectionRequest';
import { SWOTSectionResponse } from '../model/response/swotSectionResponse';
import { SWOTResourceRequest } from '../model/request/swotResourceRequest';
import { SWOTResourceResponse } from '../model/response/swotResourceResponse';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { StrategyGoalRequest, StrategyGoalSearchRequest } from '../model/request/strategyGoalRequest';
import { AimRequest } from '../model/request/aimRequest';
import { BalancedScorecardRequest } from '../model/request/balancedScorecardRequest';
import { aimResponse } from '../model/response/aimResponse';
import { BalancedScorecardResponse } from '../model/response/balancedScorecardResponse';
import { StrategyGoalResponse } from '../model/response/strategyGoalResponse';
import { StrategyRequest } from '../model/request/strategyModelRequest';
import { forkJoin } from 'rxjs';


@Injectable()
export class StrategyService extends ServiceBase {

    protected options: RequestOptions;


    constructor(protected http: Http) {
        super(http);

    }

    GetDataGeneric(urls: String[]): Observable<any> {
        this.SetHeader(true);
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });

        const arrayOfResponses = [];
        urls.forEach(element => {
            arrayOfResponses.push(this.GetData(`${this.serviceURL + element}`))
        });

        return forkJoin(arrayOfResponses);
    }


    CreateSWOTSections(swotSections: SWOTSectionRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.SWOTSection}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, swotSections, this.options);
    }

    UpdateSWOTSections(swotSections: SWOTSectionRequest): Observable<any> {

        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.SWOTSection}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, swotSections);
    }

    DeleteSWOTSections(swotSections: SWOTSectionRequest): Observable<any> {

        // this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.SWOTSection}`;
        let params: URLSearchParams = this.SetUrlParam(swotSections);
        const opt = { headers: this.headers, body: swotSections }
        // this.options = new RequestOptions({ headers: this.headers, search: params });




        return this.http.delete(url, opt);
    }



    GetSWOTSections(sectorID: number): Observable<SWOTSectionResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.SWOTSection + "/" + sectorID}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetSWOTSectionsByType(swotSectionSearchParam: SWOTSectionSearchRequest): Observable<SWOTSectionResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.SWOTSection + "/" + swotSectionSearchParam.sectionType} `;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    CreateSWOTResource(swotResource: SWOTResourceRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.SWOTResource}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, swotResource, this.options);
    }



    GetSWOTResources(): Observable<SWOTResourceResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.SWOTResource}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    CreateStrategyGoal(strategyGoalRequest: StrategyGoalRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.StrategyGoal}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, strategyGoalRequest, this.options);
    }

    UpdateStrategyGoal(strategyGoalRequest: StrategyGoalRequest): Observable<any> {

        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.StrategyGoal}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, strategyGoalRequest);
    }

    DeleteStrategyGoal(strategyGoalRequest: StrategyGoalRequest): Observable<any> {

        // this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.StrategyGoal}`;
        let params: URLSearchParams = this.SetUrlParam(strategyGoalRequest);
        const opt = { headers: this.headers, body: strategyGoalRequest }
        // this.options = new RequestOptions({ headers: this.headers, search: params });

        return this.http.delete(url, opt);
    }


    GetStrategyGoals(): Observable<StrategyGoalResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.StrategyGoal}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetStrategyGoalsBySearch(strategyGoalSearchRequest: StrategyGoalSearchRequest): Observable<StrategyGoalResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.StrategyGoal + '/Search'}`;
        let params: URLSearchParams = this.SetUrlParam(strategyGoalSearchRequest);
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }


    CreateAim(aimRequest: AimRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.Aim}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, aimRequest, this.options);
    }

    UpdateAim(aimRequest: AimRequest): Observable<any> {

        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.Aim}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, aimRequest);
    }

    DeleteAim(aimRequest: AimRequest): Observable<any> {

        // this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.Aim}`;
        let params: URLSearchParams = this.SetUrlParam(aimRequest);
        const opt = { headers: this.headers, body: aimRequest }
        // this.options = new RequestOptions({ headers: this.headers, search: params });

        return this.http.delete(url, opt);
    }


    GetAims(): Observable<aimResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.Aim}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }



    CreateBalancedScorecard(balancedScorecardRequest: BalancedScorecardRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.BalancedScorecard}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, balancedScorecardRequest, this.options);
    }

    UpdatBalancedScorecard(balancedScorecardRequest: BalancedScorecardRequest): Observable<any> {

        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.BalancedScorecard}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, balancedScorecardRequest);
    }

    DeleteBalancedScorecard(balancedScorecardRequest: BalancedScorecardRequest): Observable<any> {

        // this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.BalancedScorecard}`;
        let params: URLSearchParams = this.SetUrlParam(balancedScorecardRequest);
        const opt = { headers: this.headers, body: balancedScorecardRequest }
        // this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.delete(url, opt);
    }

    GetBalancedScorecards(): Observable<BalancedScorecardResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.BalancedScorecard}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }


    CreateStrategy(strategyRequest: StrategyRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.strategyGroup.Strategy}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, strategyRequest, this.options);
    }


    // UploadPropertyGroupOwnerContactTempalteExcelEntity(uploadServiceEntity: UploadPropertyGroupOwnerContactTemplateRequestDTO): Observable<PropertyGroupOwnerContactResponseDTO> {
    //     this.SetHeader(true)
    //     const url = `${this.serviceURL + '/propertygroupownercontact'}`;
    //     this.options = new RequestOptions({ headers: this.headers });
    //     return this.http.post(url, uploadServiceEntity, { headers: this.headers })
    //         .map(response => response.json())
    //         .catch((error: any) => Observable.throw(error.json().error || 'Server error'));

    // }


    // //AddPropertyUnitProcessor
    // addUnitRequest(request: AddUnitRequest): Observable<ActionResponseDTO> {
    //     this.SetHeader(true);

    //     const url = `${this.serviceURL}/` + request.propertyGroupId + '/properties/land/' + request.landId + '/building/' + request.buildingId + '/units/add';
    //     this.options = new RequestOptions({ headers: this.headers });
    //     return this.http.post(url, request, { headers: this.headers })
    //         .map(response => response.json())
    //         .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
    // }

}

import { Component, AfterViewInit, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { ROUTES } from './menu-items';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Group } from '../model/groupModel';
import { User } from '../model/userModel';
import { getUser } from '../common/extra-services';
declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  user: User;
  authPath: String[];

  public sidebarnavItems: any[];
  // this is for the open close
  addExpandClass(element: any, element2: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any, element2: any) {
    if(element2.path === "/forms/goToTasksDashboard"){
      window.open('http://192.168.2.40:83/', '_blank');
    }
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    this.user = getUser();
    this.authPath = this.getUrls(this.user.groupList);
    this.sidebarnavItems = this.filterOnGroup(this.sidebarnavItems);

  }

  filterOnGroup(list): [] {
    let returnedList: any = [];
    list.forEach(item => {
      try {
        if (item.submenu.length > 0) {
          let x = this.filterOnGroup(item.submenu);
          if (x.length > 0) {
            item.submenu = x;
            returnedList.push(item);
          }
        } else {
          if (this.isVisible(item)) {
            returnedList.push(item);
          }
        }

      } catch (error) {
        console.log(error);
      }
    });
    return returnedList;
  }


  getUrls(list: Group[]): string[] {
    let returnedList: string[] = [];
    if (list.length > 0) {
      list.forEach(group => {
        let pageList = group.pageList;
        if (pageList.length > 0) {
          pageList.forEach(page => {
            returnedList.push(page.path);
          });
        }
      });
    }
    return returnedList;
  }

  isVisible(item): boolean {
      let path = item.path;
      let exists = false;

      this.authPath.forEach(url => {
        if (url == path) {
          exists = true;
        }
      });
      return exists;
    }
}

import { BaseEntity } from "./base/base-entity.model";
import { Page } from "./pageModel";

export class Group extends BaseEntity {
    constructor() {
        super();
    }
    permissions: string;
    code: string;
    css: string;
    pageList: Page[];
    groupPages: GroupPage[];

}
export class GroupPage {
    pageId: number;
    groupId: number;
}
import { Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { getUser } from 'src/app/shared/common/extra-services';
import { ServiceUrls } from 'src/app/shared/common/service-urls';
import { QuickAccessMenuItem } from 'src/app/shared/model/administration/quickAccessMenuItemModel';
import { AdministratorService } from 'src/app/shared/service/administrator-service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';

@Component({
  selector: 'app-quick-access-menu',
  templateUrl: './quick-access-menu.component.html',
  styleUrls: ['./quick-access-menu.component.css']
})
export class QuickAccessMenuComponent implements OnInit {

  quickAccessMenuItems: QuickAccessMenuItem[] = [];

  @Output() onMenuItemClickEvent = new EventEmitter<boolean>();

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private apiService: AdministratorService,
    private localStorage: LocalStorageService,
    private router: Router) { }

  ngOnInit() {

    this.loadQuickAccessMenuItems();
  }

  loadQuickAccessMenuItems() {
    let query = "?UserId=" + getUser().id;
    this.apiService.Get(ServiceUrls.adminGroup.QuickAccessMenu.GetQuickAccessMenuItems + query).toPromise().then(res => {
      if (res.responseCode == 200) {
        this.quickAccessMenuItems = res.response.quickAccessMenuItemList;
      }
    });
  }

  onMenuItemClick(item: QuickAccessMenuItem) {
    this.localStorage.clearQuickAccessMenuData();
    this.localStorage.setQuickAccessMenuData(item.tabNumber, item.action);
    this.onMenuItemClickEvent.emit(false);
    if (this.router.url == item.route) {
      window.location.reload();
    } else {
      this.router.navigate([item.route]);
    }
  }

  closeMenu() {
    this.onMenuItemClickEvent.emit(false);
  }

}

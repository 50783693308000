import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
//import { } from '@progress/kendo-angular-l10n';
//import { } from 'rxjs';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AgmCoreModule } from '@agm/core';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';


//import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';

import { AdministratorService } from './shared/service/administrator-service';

import { StrategyService } from './shared/service/strategy-service';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { DialogModule, SharedModule } from '@progress/kendo-angular-dialog';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { PerformanceService } from './shared/service/performance-service';
import { ExcellenceKpiService } from './shared/service/excellenceKpi-service';
import { RTL } from '@progress/kendo-angular-l10n';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { QuickAccessMenuComponent } from './layouts/quick-access-menu/quick-access-menu.component';
import { LocalStorageService } from './shared/services/local-storage.service';
// import { UiSwitchModule } from 'ngx-toggle-switch';

// import { NgxEchartsModule } from 'ngx-echarts';
// import { EChartOption, ECharts } from 'echarts';
// import { init } from 'echarts/lib/echarts';
// import * as echarts from 'echarts';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    // NgxSpinner,
    BreadcrumbComponent,
    SidebarComponent,
    QuickAccessMenuComponent
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    NgbModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forRoot(Approutes),
    PerfectScrollbarModule,
    AgmCoreModule.forRoot({ apiKey: 'AIzaSyBUb3jDWJQ28vDJhuQZxkC0NXr_zycm8D0' }),
    NotificationModule,
    DialogModule,
    DateInputsModule,
    BrowserAnimationsModule,
    // UiSwitchModule,
    IntlModule,   //ComboBoxModule


  ],

  providers: [
    LocalStorageService,
    AdministratorService,
    PerformanceService,
    StrategyService, { provide: RTL, useValue: true },
    {

      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG

    }, ExcellenceKpiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  public pageTitle: string = 'Test Title';
}

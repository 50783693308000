export class BaseEntity {

  id: number;
  name: string;
  nameArabic: string;
  description: string;
  descriptionArabic: string;

  createdBy: number;
  modifiedBy: number;
  createdDate: Date;
  modifiedDate: Date;

  isDeleted: number;

  trashedBy: number;
  trashedDate: Date;
  isTrashed: number;

  qResults: number[];
  any:string;

  extra: any;
}
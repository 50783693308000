import { Injectable } from '@angular/core';
import { Headers, Http, Response, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import { environment } from '../../../environments/environment';
//import { ToastsManager, ToastOptions } from 'ng2-toastr/ng2-toastr';
//import { HelperFunctions } from '../common/helpers';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
//import { AuthService } from '../../modules/shared/services/guard/auth.service';

@Injectable()
export abstract class ServiceBase {
    protected serviceURL: string;
    protected headers: Headers;
    protected options: RequestOptions;
    //public toaster: ToastsManager;
    //authService: AuthService;
    protected isAuthenticated: boolean;
    private token: string;
    constructor(protected http: Http) {
    //    this.authService = new AuthService(http);
        this.SetHeader(false);
        this.serviceURL = environment.apiUrl;

        this.options = new RequestOptions({ headers: this.headers });
    }

    protected GetData(url: string): Observable<any> {
        return this.http.get(url, this.options)
            .map(response => {
                this.UpdateToken(response);
                return response.json();
            })
            .catch(this.handleError)
    }

    protected SetUrlParam(obj: any): URLSearchParams {
        const params: URLSearchParams = new URLSearchParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] instanceof Date) {
                    const val = obj[key];
                    params.set(key, val.toLocaleString());
                }
                else {
                    const val = obj[key];
                    params.set(key, val);
                }
            }
        }
        return params;
    }

    protected SetUrlParamComplex(obj: any): URLSearchParams {

        const params: URLSearchParams = new URLSearchParams();

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                let prmKey = '';
                let prmVal = '';
                if (obj.hasOwnProperty(key)) {
                    const val = obj[key];
                    prmKey = key;
                    if (typeof val === 'object') {
                        for (const cKey in val) {
                            if (val.hasOwnProperty(cKey)) {
                                const cPrmKey: string = prmKey + '.' + cKey;
                                prmVal = val[cKey];
                                params.set(cPrmKey, prmVal);
                            }
                        }
                    } else {
                        prmVal = val;
                        params.set(prmKey, prmVal);
                    }
                }
            }
        }

        return params;
    }

    protected PostData(url: string, params: any): Observable<any> {
        return this.http.post(url, params)
            .map(response => {
                this.UpdateToken(response);
                return response.json();
            })
            .catch(this.handleError);
    }

    protected Put(url: string, params: any): Observable<any> {
        return this.http.put(url, params, this.options)
            .map(response => {
                return response.json();
            })
            .catch(this.handleError);
    }

    protected Delete(url: string, id: any): Observable<any> {
        return this.http.delete(url, this.options)
            .map(response => {
               // this.UpdateToken(response);
                return response.json();
            })
            .catch(this.handleError);
    }

    protected handleError(error: any): Promise<any> {
        if (error && !error.ok && error.status == 401) {
            sessionStorage.clear();
            //window.location.href = '/login';
        }
        return Promise.reject(error.message || error);
    }

    private UpdateToken(response: Response): void {
        if (response.headers.get('TOKEN')) {
            this.token = response.headers.get('TOKEN');
            sessionStorage.setItem('id_token', this.token);
        }
    }

    protected SetHeader(isAuthenticated: boolean): void {
        if (isAuthenticated) {
            if (this.token === undefined || this.token) {
                const tokenId = sessionStorage.getItem('id_token');
                this.token = tokenId;
            }
            this.headers = new Headers({
                'Content-Type': 'application/json',
                'Accept': 'q=0.8;application/json;q=0.9'
                //'TOKEN': this.token
            });
        } else {
            this.headers = new Headers({
                'Content-Type': 'application/json',
                'Accept': 'q=0.8;application/json;q=0.9'
            });
        }
    }
}

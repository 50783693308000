import { Component, OnInit, HostListener, Inject, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { interval, Subscription } from 'rxjs';
import { getUser } from 'src/app/shared/common/extra-services';
import { ServiceUrls } from 'src/app/shared/common/service-urls';
import { checkLanguageAdv, Translation } from 'src/app/shared/common/translation';
import { AdministratorService } from 'src/app/shared/service/administrator-service';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};

  constructor(public router: Router,
    @Inject(LOCALE_ID) public locale: string,
    private apiService: AdministratorService
  ) { }

  tabStatus = 'justified';

  public isCollapsed = false;

  public innerWidth: any;
  public defaultSidebar: any;
  public showSettings = false;
  public showMobileMenu = false;
  public expandLogo = false;

  public year = new Date().getFullYear();

  options = {
    theme: 'light', // two possible values: light, dark
    dir: this.locale == 'ar-AE' ? 'rtl' : 'ltr', // two possible values: ltr, rtl
    layout: 'vertical', // fixed value. shouldn't be changed.
    sidebartype: 'mini-sidebar', // four possible values: full, iconbar, overlay, mini-sidebar
    sidebarpos: 'fixed', // two possible values: fixed, absolute
    headerpos: 'fixed', // two possible values: fixed, absolute
    boxed: 'full', // two possible values: full, boxed
    navbarbg: 'skin6', // six possible values: skin(1/2/3/4/5/6)
    sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
    logobg: 'skin5' // six possible values: skin(1/2/3/4/5/6)
  };

  Logo() {
    this.expandLogo = !this.expandLogo;
  }

  ngOnInit() {
    if (this.router.url === '/') {
      this.router.navigate(['/dashboard/classic']);
    }
    this.defaultSidebar = this.options.sidebartype;
    this.handleSidebar();

    this.loadTotalPending();
    this.loadAppVersion();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.handleSidebar();
  }

  handleSidebar() {
    this.innerWidth = window.innerWidth;
    switch (this.defaultSidebar) {
      case 'full':
      case 'iconbar':
        if (this.innerWidth < 1170) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      case 'overlay':
        if (this.innerWidth < 767) {
          this.options.sidebartype = 'mini-sidebar';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  toggleSidebarType() {
    switch (this.options.sidebartype) {
      case 'full':
      case 'iconbar':
        this.options.sidebartype = 'mini-sidebar';
        break;

      case 'overlay':
        this.showMobileMenu = !this.showMobileMenu;
        break;

      case 'mini-sidebar':
        if (this.defaultSidebar === 'mini-sidebar') {
          this.options.sidebartype = 'full';
        } else {
          this.options.sidebartype = this.defaultSidebar;
        }
        break;

      default:
    }
  }

  taskCount = 0;
  showMenu = false;
  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  private intervalSubscription: Subscription;
  loadTotalPending() {
    this.intervalSubscription = interval(5000).subscribe(() => {
      let query = "?UserId=" + getUser().id;
      this.apiService.Get(ServiceUrls.adminGroup.QuickAccessMenu.GetTotalPending + query).toPromise().then(res => {
        this.taskCount = res;
      });
    });
  }

  showUpdateBanner = false;
  loadAppVersion() {
    this.intervalSubscription = interval(60000).subscribe(() => {
      this.apiService.Get(ServiceUrls.Extra.GetAppVersion).toPromise().then(res => {
        let VERSION = localStorage.getItem("APP_VERSION");
        if(VERSION != res){
          this.showUpdateBanner = true;
          localStorage.setItem("APP_VERSION", res);
        }
      });
    });
  }

  ngOnDestroy() {
    // Unsubscribe from the interval observable to prevent memory leaks
    this.intervalSubscription.unsubscribe();
  }

  newVersionMessage = checkLanguageAdv(this.locale, Translation.newVersionAvailable);
  refreshButtonLabel = checkLanguageAdv(this.locale, Translation.refresh);
  reloadPage() {
    window.location.reload();
  }
}

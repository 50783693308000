import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable } from 'rxjs/Rx';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { ServiceBase } from './service.base';
import { ServiceUrls } from '../common/service-urls';

import { OrganizationRequest } from '../../shared/model/request/organizationRequest';
import { SectorRequest } from '../../shared/model/request/sectorRequest';
import { DepartmentRequest } from '../../shared/model/request/departmentRequest';
import { SectionRequest } from '../../shared/model/request/sectionRequest';
import { EmployeeRequest } from '../model/request/employeeRequest';
import { SectionKpiRequest } from '../model/request/sectionKpiRequest';
import { AggregationMethodRequest } from '../model/request/aggregationMethodRequest';
import { CalculationPeriodRequest } from '../model/request/calculationPeriodRequest';
import { ValueTypeRequest } from '../model/request/valueTypeRequest';


import { OrganizationResponse } from '../model/response/organizationResponse';
import { SectorResponse } from '../model/response/sectorResponse';
import { DepartmentResponse } from '../model/response/departmentResponse';
import { SectionResponse } from '../model/response/sectionResponse';
import { EmployeeResponse } from '../model/response/employeeResponse';
import { SectionKpiResponse } from '../model/response/sectionKpiResponse';
import { forkJoin } from 'rxjs';







@Injectable()
export class AdministratorService extends ServiceBase {

    protected options: RequestOptions;


    constructor(protected http: Http) {
        super(http);

    }

    Get(stringUrl): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    Create(request, stringUrl): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, request, this.options);
    }

    Delete(request, stringUrl): Observable<any> {
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam(request);
        const opt = { headers: this.headers, body: request }
        return this.http.delete(url, opt);
    }

    Update(request, stringUrl): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, request);
    }

    GetDataGeneric(urls: String[]): Observable<any> {
        this.SetHeader(true);
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });

        const arrayOfResponses = [];
        urls.forEach(element => {
            arrayOfResponses.push(this.GetData(`${this.serviceURL + element}`))
        });

        return forkJoin(arrayOfResponses);
    }


    CreateOrganization(organization: OrganizationRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Organization}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, organization, this.options);
    }

    CreateSector(sector: SectorRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Sector}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, sector, this.options);
    }

    CreateDepartment(department: DepartmentRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Department}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, department, this.options);
    }

    CreateSection(section: SectionRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Section}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, section, this.options);
    }

    CreateEmployee(employee: EmployeeRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Employee}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, employee, this.options);
    }

    CreateSectionKpi(sectionKpi: SectionKpiRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.SectionKpi}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, sectionKpi, this.options);
    }

    CreateAggregationMethod(aggregationMethod: AggregationMethodRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.AggregationMethod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, aggregationMethod, this.options);
    }

    CreateCalculationPeriod(calculationPeriod: CalculationPeriodRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.CalculationPeriod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, calculationPeriod, this.options);
    }

    CreateValueType(valueType: ValueTypeRequest): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.ValueType}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, valueType, this.options);
    }

    GetOrganizations(): Observable<OrganizationResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Organization}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetSectors(): Observable<SectorResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Sector}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetDepartments(): Observable<DepartmentResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Department}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetSections(): Observable<SectionResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Section}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetSectionsByDepartment(departmentId: number): Observable<SectionResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Section + '/' + departmentId}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetEmployees(): Observable<EmployeeResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.Employee}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetSectionKpis(): Observable<SectionKpiResponse> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.SectionKpi}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetAggregationMethods(): Observable<AggregationMethodRequest> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.AggregationMethod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetCalculationPeriods(): Observable<CalculationPeriodRequest> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.CalculationPeriod}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    GetValueTypes(): Observable<ValueTypeRequest> {
        this.SetHeader(true);
        const url = `${this.serviceURL + ServiceUrls.adminGroup.ValueType}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }



    // UploadPropertyGroupOwnerContactTempalteExcelEntity(uploadServiceEntity: UploadPropertyGroupOwnerContactTemplateRequestDTO): Observable<PropertyGroupOwnerContactResponseDTO> {
    //     this.SetHeader(true)
    //     const url = `${this.serviceURL + '/propertygroupownercontact'}`;
    //     this.options = new RequestOptions({ headers: this.headers });
    //     return this.http.post(url, uploadServiceEntity, { headers: this.headers })
    //         .map(response => response.json())
    //         .catch((error: any) => Observable.throw(error.json().error || 'Server error'));

    // }


    // //AddPropertyUnitProcessor
    // addUnitRequest(request: AddUnitRequest): Observable<ActionResponseDTO> {
    //     this.SetHeader(true);

    //     const url = `${this.serviceURL}/` + request.propertyGroupId + '/properties/land/' + request.landId + '/building/' + request.buildingId + '/units/add';
    //     this.options = new RequestOptions({ headers: this.headers });
    //     return this.http.post(url, request, { headers: this.headers })
    //         .map(response => response.json())
    //         .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
    // }

}

import { BaseEntity } from "./base/base-entity.model";

export class Page extends BaseEntity {
    
    constructor(){
        super();
    }
    
  
    path: string;
    
}
import { Injectable } from "@angular/core";
import { ServiceBase } from "./service.base";
import { Http, RequestOptions, URLSearchParams } from '@angular/http';
import { Observable, forkJoin } from "rxjs";

@Injectable()
export class ExcellenceKpiService extends ServiceBase {

    protected options: RequestOptions;


    constructor(protected http: Http) {
        super(http);

    }

    GetDataGeneric(urls: String[]): Observable<any> {
        this.SetHeader(true);
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });

        const arrayOfResponses = [];
        urls.forEach(element => {
            arrayOfResponses.push(this.GetData(`${this.serviceURL + element}`))
        });

        return forkJoin(arrayOfResponses);
    }

    Get(stringUrl): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.GetData(url);
    }

    Create(stringUrl, request): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.http.post(url, request, this.options);
    }

    Delete(stringUrl, request): Observable<any> {
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam(request);
        const opt = { headers: this.headers, body: request }
        return this.http.delete(url, opt);
    }

    Update(stringUrl, request): Observable<any> {
        this.SetHeader(true);
        const url = `${this.serviceURL + stringUrl}`;
        let params: URLSearchParams = this.SetUrlParam("");
        this.options = new RequestOptions({ headers: this.headers, search: params });
        return this.Put(url, request);
    }
}
import { Component, AfterViewInit, Inject, LOCALE_ID, OnInit } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { User } from '../model/userModel';
import { Router } from '@angular/router';
import { AdministratorService } from '../service/administrator-service';
import { ServiceUrls } from '../common/service-urls';
import { exportDropDownSettings, getUser, removeSession, removeUser, setUser } from '../common/extra-services';
import { Subscription, interval, Observable, Subject } from 'rxjs';
import { MapUser } from '../model/mapUserModel';
import { StorageServiceService } from '../service/storage-service.service';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit, OnInit {

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  public isPendingApprovalMoreThanZero = false;
  public isShake = false;
  public pendingApprovalNumber = 0;

  public user: User;
  public name: string;
  public email: string;

  private subscription: Subscription;

  constructor(@Inject(LOCALE_ID) public locale: string,
    private modalService: NgbModal,
    private adminstrationService: AdministratorService,
    private router: Router) { }
  ngOnInit(): void {
    this.extractUserData();
    this.selectedMapUser = new MapUser();

    const source = interval(10000);
    if (getUser() != null) {
      this.loadMapUser();
      this.subscription = source.subscribe(val => this.loadPendingApprovalNotification());
    }
  }

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: 'btn-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM'
    },
    {
      btn: 'btn-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    },
    {
      btn: 'btn-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    },
    {
      btn: 'btn-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:30 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    },
    {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',
      subject: 'I am a singer!',
      time: '9:08 AM'
    },
    {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }
  ];

  quickList: Object[] = [
    {
      title: this.locale == 'ar-AE' ? 'الإعتماد' : 'Approval',
      route: '/forms/formApproval/Approve'
    }
  ]


  ngAfterViewInit() {
    // this.extractUserData();
    // this.selectedMapUser = new MapUser();

    // const source = interval(10000);
    // if (getUser() != null) {
    //   this.loadMapUser();
    //   this.subscription = source.subscribe(val => this.loadPendingApprovalNotification());
    // }

  }

  extractUserData() {
    this.user = getUser();
    if (this.user != null) {
      this.name = this.locale == 'ar-AE' ? this.user.nameArabic : this.user.name;
      this.email = this.user.email;
    }
  }

  logout() {
    removeUser();
    removeSession();
    this.router.navigate(['/authentication/login']);
  }

  loadPendingApprovalNotification() {

    if (getUser() != null) {
      this.adminstrationService.Get(ServiceUrls.adminGroup.ApprovalCycleAssignStep + "?UserId=" + this.user.id).toPromise().then(res => {
        let approvalList = res.response.approvalCycleAssignStepList;
        if (approvalList.length > 0) {
          this.isPendingApprovalMoreThanZero = true;
          if (this.pendingApprovalNumber != approvalList.length) {
            this.isShake = true;
            this.playNotificationSound();

          }
          this.pendingApprovalNumber = approvalList.length;

        } else {
          this.isPendingApprovalMoreThanZero = false;
          this.pendingApprovalNumber = approvalList.length;
        }
      });
    }
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

  playNotificationSound() {
    let audio = new Audio();
    audio.src = "../../../assets/audio/inflicted.mp3";
    audio.load();
    try {
      audio.play();
    } catch (error) {

    }
    window.setTimeout(() => this.isShake = false, 1500);
  }


  goToApproval() {
    this.router.navigate(['/forms/formApproval/Approve']);

  }
  goToPool() {
    this.router.navigate(['/forms/formApproval/Pool']);

  }


  //////////////////////////////// Map User //////////////////////////////////////

  singleDropDown = exportDropDownSettings('single', this.locale);
  mapUsers: MapUser[] = [];
  selectedMapUser: MapUser;

  onValueChange(mapUser: MapUser) {
    if (mapUser != undefined) {
      this.selectedMapUser = mapUser;
      this.user.on = mapUser.on;
      this.user.onId = mapUser.onId;
      setUser(this.user);
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate([this.router.url]);
      window.location.reload();
    }
  }

  loadMapUser() {
    let queryString = "?UserId=" + this.user.id;
    this.adminstrationService.Get(ServiceUrls.Extra.GetAllMapUsers + queryString).toPromise().then(res => {
      if (res.responseCode == 200) {
        this.mapUsers = res.response.mapUserList;
        this.changeData();
      }
    });
  }

  changeLanguage() {
    let isProduction = environment.production;
    let siteURL = '';

    if (isProduction) {
      siteURL = this.locale == 'ar-AE' ? 'http://192.168.2.40:81/en/' : 'http://192.168.2.40:80/';
    } else {
      siteURL = this.locale == 'ar-AE' ? 'http://localhost:4201/en/' : 'http://localhost:4200/';
    }
    window.location.href = siteURL;
  }

  changeData() {
    let mapUserMaxCreationDate = null;
    let mapUserIndex = -1;
    this.mapUsers.forEach((obj, index) => {
      if (mapUserMaxCreationDate == null) {
        mapUserMaxCreationDate = new Date(obj.createdDate);
        mapUserIndex = index;
      }
      else {
        let currentCreationDate = new Date(obj.createdDate);
        if (currentCreationDate > mapUserMaxCreationDate) {
          mapUserMaxCreationDate = currentCreationDate;
          mapUserIndex = index;
        }
      }
      let modelOn = null;
      if (obj.section != null) {
        modelOn = obj.section
      } else if (obj.department != null) {
        modelOn = obj.department
      } else if (obj.sector != null) {
        modelOn = obj.sector
      }
      obj.name = modelOn.name;
      obj.nameArabic = modelOn.nameArabic;
    })
    let selectedMapUser = this.mapUsers.find(obj => obj.onId == this.user.onId);
    this.selectedMapUser = selectedMapUser != undefined ? selectedMapUser : this.mapUsers[mapUserIndex];

    this.user.on = this.selectedMapUser.on;
    this.user.onId = this.selectedMapUser.onId;
    setUser(this.user);
  }

}


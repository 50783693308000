import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private quickAccessMenuPrefix = 'quickAccessMenu_';

  setQuickAccessMenuData(tabNumber: number, action: string) {
    localStorage.setItem(`${this.quickAccessMenuPrefix}tabNumber`, String(tabNumber));
    localStorage.setItem(`${this.quickAccessMenuPrefix}action`, action);
  }

  getQuickAccessMenuData(): { tabNumber: number, action: string } | null {
    const tabNumberString = localStorage.getItem(`${this.quickAccessMenuPrefix}tabNumber`);
    const tabNumber = tabNumberString ? parseInt(tabNumberString, 10) : null;
    const action = localStorage.getItem(`${this.quickAccessMenuPrefix}action`);
    return tabNumber == null || action == null ? null : { tabNumber, action };
  }

  clearQuickAccessMenuData() {
    localStorage.removeItem(`${this.quickAccessMenuPrefix}tabNumber`);
    localStorage.removeItem(`${this.quickAccessMenuPrefix}action`);
  }
}
